import axiosInstance from "./axiosInstance";

export const create_tag = async ({ project_id, tag }) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/tag/create",
    method: "PUT",
    data: { project_id: project_id, tag: tag },
  });
  return response.data;
};

export const add_tag_to_brainstorm_session = async ({
  project_id,
  tag,
  session_id,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/tag/add",
    method: "PUT",
    data: {
      project_id: project_id,
      tag: tag,
      object_type: "session",
      session_id: session_id,
    },
  });
  return response.data;
};

export const remove_tag_from_brainstorm_session = async ({
  project_id,
  tag,
  session_id,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/tag/remove",
    method: "DELETE",
    data: {
      project_id: project_id,
      tag: tag,
      object_type: "session",
      session_id: session_id,
    },
  });
  return response.data;
};

export const add_tag_to_brainstorm_idea = async ({
  project_id,
  tag,
  idea_id,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/tag/add",
    method: "PUT",
    data: {
      project_id: project_id,
      tag: tag,
      object_type: "idea",
      idea_id: idea_id,
    },
  });
  return response.data;
};
export const remove_tag_from_brainstorm_idea = async ({
  project_id,
  tag,
  idea_id,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/tag/remove",
    method: "DELETE",
    data: {
      project_id: project_id,
      tag: tag,
      object_type: "idea",
      idea_id: idea_id,
    },
  });
  return response.data;
};
