import { get_user_calendar } from "api/calendar";
import useSWR from "swr";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

import { useMemo, useState } from "react";
import { LoadingPage, Title } from "components";
import { Dialog, DialogContent } from "@/components/ui/dialog";

import Prando from "prando";
import { get_call_info } from "api";
import GrantEvent from "./GrantEvent";
import ActionItemEvent from "./ActionItemEvent";

export default function Calendar() {
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [selectedEventInfo, setSelectedEventInfo] = useState(null);
  const {
    data: grantInfo,
    isLoading: isGettingGrantInfo,
    error: grantInfoError,
  } = useSWR(selectedEventInfo?.grant_id, () =>
    get_call_info({ call_id: selectedEventInfo?.grant_id })
  );
  const { data: projects, isLoading } = useSWR(
    "user_calendar",
    get_user_calendar
  );
  const events = useMemo(() => {
    if (!projects) return [];

    let temp_events = [];
    const rng = new Prando(1000); // Used to generate a random color for each event, except grant deadlines (fuchsia)
    // Parse each action item into an event: https://fullcalendar.io/docs/event-parsing
    for (const project of projects) {
      const hue = rng.nextInt(0, 360);
      const saturation = rng.nextInt(70, 100);
      const luminosity = rng.nextInt(20, 50);

      if (project.action_items) {
        const project_events = project?.action_items.map((action_item) => ({
          color: `hsl(${hue} ${saturation}% ${luminosity}%)`,
          title: action_item.item,
          id: action_item.action_item_id,
          date: action_item.due_date,
          info: {
            project_name: project.project_name,
            project_id: project.project_id,
            title: action_item.item,
            date: action_item.due_date,
            description: action_item.description,
          },
        }));
        temp_events = temp_events.concat(project_events);
      }
      if (project.selected_grant_info) {
        const grant_info = project.selected_grant_info;
        temp_events = temp_events.concat([
          {
            color: "fuchsia",
            title: "\u2605 " + grant_info.agency_name,
            id: `${project.project_id}-${grant_info.grant_id}`,
            date: grant_info.close_date,
            info: {
              project_name: project.project_name,
              project_id: project.project_id,
              title: grant_info.agency_name,
              date: grant_info.close_date,
              description: grant_info.opportunity_title,
              grant_id: grant_info.grant_id,
            },
          },
        ]);
      }
    }

    return temp_events;
  }, [projects]);

  const handleEventClick = (info) => {
    setSelectedEventInfo(info.event._def.extendedProps.info);
    setIsEventDialogOpen(true);
  };

  if (isLoading) return <LoadingPage />;

  return (
    <div className="max-w-screen-lg">
      <Title value="Calendar" className="text-4xl" />
      <div>
        Our calendar helps you keep track of important dates across all of your
        projects, including grant application deadlines and action item due
        dates. It's designed to give you a clear view of everything you need to
        stay on schedule and complete your applications on time. Keep everything
        in one place and never miss a deadline.
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventClick={handleEventClick}
      />
      <Dialog open={isEventDialogOpen} onOpenChange={setIsEventDialogOpen}>
        <DialogContent className="max-w-screen-md">
          {selectedEventInfo?.grant_id ? (
            <GrantEvent
              grantInfo={grantInfo}
              eventInfo={selectedEventInfo}
              isLoading={isGettingGrantInfo}
            />
          ) : (
            <ActionItemEvent eventInfo={selectedEventInfo} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
