import { edit_heading_outline } from "api/write_space";
import { useEffect, useRef, useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { EllipsisVerticalIcon } from "lucide-react";
import RegenerateOutlineModal from "./RegenerateOutlineModal";
import { AutosizeTextarea } from "@/components/ui/autosizetextarea";

export default function Heading({
  headings,
  templateId,
  projectId,
  depth,
  heading,
  heading_id,
  outline,
  label,
  headingLabels,
}) {
  useEffect(() => {
    setTempOutline(outline);
  }, [outline]);
  const [tempOutline, setTempOutline] = useState(outline || []);
  const [editIndex, setEditIndex] = useState(null);

  const { trigger: updateOutline } = useSWRMutation(
    "update_outline",
    (_, { arg: new_outline }) => {
      edit_heading_outline({
        template_id: templateId,
        heading_id: heading_id,
        outline: new_outline,
      });
      return new_outline;
    },
    {
      onSuccess: (new_outline) => {
        mutate(
          `/write/template/${projectId}/${templateId}`,
          (headings) =>
            headings.map((heading) =>
              heading.heading_id === heading_id
                ? { ...heading, outline: new_outline }
                : heading
            ),
          { revalidate: false }
        );
      },
    }
  );

  const handleOnKeyDown = (e, index) => {
    // If the user presses enter, we insert a new bullet point
    if (e.key === "Enter") {
      e.preventDefault();
      setEditIndex(index + 1);
      setTempOutline((o) => [
        ...o.slice(0, index + 1),
        "",
        ...o.slice(index + 1),
      ]);
    }
    if (e.key === "Backspace" && !e.target.value) {
      const el = document.getElementById(`${heading_id}-${index}`);
      setTempOutline((o) => [...o.slice(0, index), ...o.slice(index + 1)]);
      el.blur();
    }
  };

  const handleOnChange = (e, index) => {
    setTempOutline((o) => [
      ...o.slice(0, index),
      e.target.value,
      ...o.slice(index + 1),
    ]);
  };

  const handleBlur = () => {
    if (editIndex === null) {
      // First filter out all empty points
      const new_outline = tempOutline.filter((o) => o.length);
      setTempOutline(new_outline);

      // Check if each bullet point is the same.
      if (!new_outline?.length && !outline) return;
      const hasChanged = new_outline?.join("-") != outline?.join("-");
      if (hasChanged) {
        updateOutline(new_outline);
      }
    }
  };

  // Handle focusing on correct element
  useEffect(() => {
    if (editIndex) {
      const el = document.getElementById(`${heading_id}-${editIndex}`);

      el?.focus();
      setEditIndex(null);
    }
  }, [editIndex, tempOutline]);

  return (
    <div style={{ marginLeft: depth * 10 }}>
      <div className="flex justify-between">
        <div className="text-lg underline font-semibold">
          {label} {heading || "Untitled"}
        </div>
        {outline && (
          <RegenerateOutlineModal
            templateId={templateId}
            projectId={projectId}
            heading_id={heading_id}
            headings={headings}
            headingLabels={headingLabels}
          >
            <button className="hover:text-fuchsia">
              <EllipsisVerticalIcon />
            </button>
          </RegenerateOutlineModal>
        )}
      </div>
      <div>
        <ul className="list-outside list-disc pl-4 text-sm">
          {tempOutline?.map((o, i) => (
            <li key={i}>
              <AutosizeTextarea
                id={`${heading_id}-${i}`}
                className="resize-none border-0 py-0 my-2"
                minHeight={0}
                resize="none"
                value={o}
                onBlur={() => handleBlur(i)}
                onKeyDown={(e) => handleOnKeyDown(e, i)}
                onChange={(e) => handleOnChange(e, i)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
