import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Input } from "@/components/ui/input";
import { DatePicker } from "@/components/ui/datepicker";
import Select from "react-select";
import { Switch } from "@/components/ui/switch";

import { format } from "date-fns";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { mutate } from "swr";
import { find_calls } from "api";
import { LoadingPage } from "components";
import { useFeedback } from "stores/useFeedbackStore";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link } from "react-router-dom";
const agencyOptions = [
  { value: "NIH", label: "NIH" },
  { value: "NSF", label: "NSF" },
  { value: "DOJ", label: "DOJ" },
  { value: "IMLS", label: "IMLS" },
  { value: "NEA", label: "NEA" },
  { value: "DOI", label: "DOI" },
  { value: "USDA", label: "USDA" },
  { value: "DOE", label: "DOE" },
  { value: "NASA", label: "NASA" },
  { value: "DOT", label: "DOT" },
  { value: "EPA", label: "EPA" },
  { value: "NEH", label: "NEH" },
  { value: "DOS", label: "DOS" },
  { value: "DOD", label: "DOD" },
  { value: "ED", label: "ED" },
  { value: "HHS", label: "HHS" },
  { value: "Other", label: "Other" },
];
const fundingOptions = [
  { value: "ACA", label: "Affordable Care Act" },
  { value: "AG", label: "Agriculture" },
  { value: "AR", label: "Arts" },
  { value: "BC", label: "Business and Commerce" },
  { value: "CD", label: "Community Development" },
  { value: "CP", label: "Consumer Protection" },
  { value: "DPR", label: "Disaster Prevention and Relief" },
  { value: "ED", label: "Education" },
  { value: "ELT", label: "Employment, Labor and Training" },
  { value: "EN", label: "Energy" },
  { value: "ENV", label: "Environment" },
  { value: "FN", label: "Food and Nutrition" },
  { value: "HL", label: "Health" },
  { value: "HO", label: "Housing" },
  { value: "HU", label: "Humanities" },
  { value: "ISS", label: "Income Security and Social Services" },
  { value: "IS", label: "Information and Statistics" },
  { value: "LJL", label: "Law, Justice and Legal Services" },
  { value: "NR", label: "Natural Resources" },
  { value: "RA", label: "Recovery Act" },
  { value: "RD", label: "Regional Development" },
  {
    value: "ST",
    label: "Science and Technology and other Research and Development",
  },
  { value: "T", label: "Transportation" },
  { value: "O", label: "Other" },
];

export default function Search({
  projectId,
  calls,
  setIsShowingResults,
  hasEditorAccess,
}) {
  const { setHasGivenFeedback } = useFeedback(projectId);

  const [promptToUpgrade, setPromptToUpgrade] = useState(false);

  const [grantFloor, setGrantFloor] = useState(0);
  const [closingDate, setClosingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [costSharing, setCostSharing] = useState(false);
  const [fundingCategory, setFundingCategory] = useState([]);
  const [agencyFilters, setAgencyFilters] = useState([]);

  const {
    isMutating: isFindCallsLoading,
    error: isFindCallsError,
    trigger: findCalls,
  } = useSWRMutation(
    `/process/calls/recommend${projectId}`,
    () =>
      find_calls({
        project_id: projectId,
        min_award_floor: grantFloor,
        cost_sharing: costSharing,
        closing_date: closingDate,
        category_of_funding:
          fundingCategory.length === 0
            ? ["all"]
            : fundingCategory.map((c) => c.value),
        agency_filter:
          agencyFilters.length === 0
            ? ["all"]
            : agencyFilters.map((a) => a.value),
      }),
    {
      onSuccess: (new_calls) => {
        if (new_calls.hasOwnProperty("credits_used")) {
          mutate(
            `get_settings`,
            (d) => ({
              ...d,
              credits_left: d.credits_left - new_calls.credits_used,
            }),
            {
              revalidate: false,
            }
          );
        }
        setIsShowingResults(true);
        setHasGivenFeedback(false);
        mutate(`get_recommended_calls/${projectId}`, () => new_calls, {
          revalidate: false,
        });
      },
      onError: (err) => {
        if (err.status === 422) {
          setPromptToUpgrade(true);
        }
      },
      throwOnError: false,
    }
  );

  const handleClosingDate = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    setClosingDate(formattedDate);
  };

  if (isFindCallsLoading) return <LoadingPage />;
  if (!hasEditorAccess)
    return (
      <>
        <p>
          Sorry, you do not have access to search calls for this project. Please
          contact the owner of this project to get access.
        </p>
        {calls?.length > 0 && (
          <button
            className="underline hover:text-fuchsia w-fit"
            onClick={() => setIsShowingResults(true)}
          >
            See current results
          </button>
        )}
      </>
    );
  return (
    <>
      <div className={"flex flex-wrap gap-x-4 gap-y-2"}>
        <div className="flex flex-col w-40">
          <div>
            <Tooltip>
              <TooltipTrigger showIcon={true}>
                <span className="text-base underline">Min. Award</span>
              </TooltipTrigger>
              <TooltipContent>
                We will only search for calls that have an award that is higher
                than the value you provide here.
              </TooltipContent>
            </Tooltip>
          </div>
          <Input
            className="w-full pl-1"
            type="number"
            value={grantFloor}
            onChange={(e) => setGrantFloor(e.target.value)}
          />
        </div>

        <div className="flex flex-col">
          <span className="text-base underline">Earliest Closing Date</span>
          <DatePicker value={closingDate} onChange={handleClosingDate} />
        </div>
        <div className="flex flex-col">
          <Tooltip>
            <TooltipTrigger>
              <span className="text-base underline">Cost Sharing</span>
            </TooltipTrigger>
            <TooltipContent>
              This filter indicates if you want to search for calls with a Cost
              Sharing or Matching requirement. If turned off, we will only
              search for ones without. If turned on, we will search for either.
            </TooltipContent>
          </Tooltip>
          <Switch
            checked={costSharing}
            onClick={() => {
              setCostSharing((p) => !p);
            }}
          ></Switch>
        </div>
        <div className="flex flex-col w-full">
          <div>
            <Tooltip>
              <TooltipTrigger>
                <div className="underline">Filter by Funding Categories</div>
              </TooltipTrigger>
              <TooltipContent>
                This filter will only search for calls if they belong to any of
                the selected categories. Leave blank if you would like to search
                for any category.
              </TooltipContent>
            </Tooltip>
          </div>

          <Select
            className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
            menuPosition="fixed"
            classNames={{
              container: ({ isFocused }) =>
                `${
                  isFocused
                    ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                    : ""
                }`,
            }}
            styles={{
              control: () => ({ display: "flex" }),
            }}
            closeMenuOnSelect={false}
            isClearable
            isMulti
            onChange={(newValue) => setFundingCategory(newValue)}
            options={fundingOptions}
            value={fundingCategory}
            placeholder={"Leave empty to search all categories"}
          />
        </div>
        <div className="flex flex-col w-full">
          {/* <div>
            <Tooltip>
              <TooltipTrigger>
                <div className="text-sm underline">Funding Category:</div>
              </TooltipTrigger>
              <TooltipContent>
                This filter will only search for calls if they belong to any of
                the selected categories. Leave blank if you would like to search
                for any category.
              </TooltipContent>
            </Tooltip>
          </div> */}
          <div className="underline">Filter by Agencies</div>
          <Select
            className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
            menuPosition="fixed"
            classNames={{
              container: ({ isFocused }) =>
                `${
                  isFocused
                    ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                    : ""
                }`,
            }}
            styles={{
              control: () => ({ display: "flex" }),
            }}
            closeMenuOnSelect={false}
            isClearable
            isMulti
            onChange={(newValue) => setAgencyFilters(newValue)}
            options={agencyOptions}
            value={agencyFilters}
            placeholder={"Leave empty to search all agencies"}
          />
        </div>
      </div>
      <div className="flex gap-x-2">
        <button
          className="btn-offblack"
          onClick={findCalls}
          disabled={isFindCallsLoading}
        >
          <span>Search for relevant calls</span>
        </button>
        {calls?.length > 0 && (
          <button
            className="underline hover:text-fuchsia"
            onClick={() => setIsShowingResults(true)}
          >
            See current results
          </button>
        )}
      </div>
      <Dialog open={promptToUpgrade} onOpenChange={setPromptToUpgrade}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Sorry, you are out of credits!</DialogTitle>
            <DialogDescription>
              Please upgrade to our pro tier to continue using this feature.
              Your credits will also refresh on the first of every month.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center">
            <Link
              className="bg-gradient-to-br from-fuchsia to-blue rounded-lg text-white p-2 hover:opacity-80"
              to="/settings/billing"
            >
              Upgrade to Pro
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
