import { useSidebar } from "@/components/ui/sidebar";
import ProjectSidebar from "./sidebars/ProjectSidebar";
import SettingsSidebar from "./sidebars/SettingsSidebar";
import { useEffect } from "react";
import HomeSidebar from "./sidebars/HomeSidebar";

export function AppSidebar({ isProjectRoute, isSettingsRoute, isHomeRoute }) {
  const { setOpen } = useSidebar();

  useEffect(() => {
    if (!isProjectRoute && !isSettingsRoute && !isHomeRoute) setOpen(false);
    else setOpen(true);
  }, [isProjectRoute, isSettingsRoute, isHomeRoute]);

  return (
    <>
      <ProjectSidebar isProjectRoute={isProjectRoute} />
      <SettingsSidebar isSettingsRoute={isSettingsRoute} />
      <HomeSidebar isHomeRoute={isHomeRoute} />
    </>
  );
}
