import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import { SWRConfig } from "swr";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./index.css";

// Layouts
import { LoginLayout, Layout } from "layouts";
// import { LoginLayoutLoader } from "layouts/LoginLayout";
// import { LayoutLoader } from "layouts/Layout";

import DeleteAccount from "pages/account/delete/Delete";

// Login Pages
import Login from "pages/account/login/Login";
import Register from "pages/account/register/Register";
import ForgotPassword from "pages/account/forgot-password/ForgotPassword";
import VerifyEmail from "pages/account/verify-email/VerifyEmail";
import ConfirmEmail from "pages/account/confirm-email/ConfirmEmail";
import ResetPassword from "pages/account/reset-password/ResetPassword";

// Home Pages
import Home from "pages/home/dashboard/Home";
import GuidelinesAssistant from "pages/guidelines-assistant/GuidelinesAssistant";

import UserBookmarkedCalls from "pages/home/bookmarked-calls/UserBookmarkedCalls";
import Profile from "pages/settings/profile/Profile";
import Billing from "pages/settings/billing/Billing";
import Integrations from "pages/settings/integrations/Integrations";
import Success from "pages/settings/billing-success/Success";
// import SearchCalls from "pages/calls/search-calls/SearchCalls";
import Error from "pages/error/Error";

import Project from "pages/project/dashboard/Project";

// Idea Space Pages
import ResearchIdea from "pages/project/ideation-space/research-idea/ResearchIdea";
import BackgroundMaterial from "pages/project/ideation-space/background-material/BackgroundMaterial";
import Brainstorm from "pages/project/ideation-space/brainstorm/Brainstorm";
import BrainstormSession from "pages/project/ideation-space/brainstorm/session/BrainstormSession";

// Grant Space Pages
import BookmarkedCalls from "pages/project/grant-space/bookmarks/BookmarkedCalls";
import SearchCalls from "pages/project/grant-space/search-calls/SearchCalls";
import RecommendedCalls from "pages/project/grant-space/recommended-calls/RecommendedCalls";

// Write Space
import ListTemplates from "pages/project/write-space/list-templates/ListTemplates";
import DocumentTemplate from "pages/project/write-space/document-template/DocumentTemplate";

// Review Space
import ListReviews from "pages/project/review-space/list-reviews/ListReviews";
import ProposalReview from "pages/project/review-space/file-review/FileReview";

// Admin Space Pages
import SelectCall from "pages/project/admin-space/select-call/SelectCall";
import ActionItems from "pages/project/admin-space/action-items/ActionItems";
import DocumentChecklist from "pages/project/admin-space/document-checklist/DocumentChecklist";

import GoogleDriveConsentResponse from "pages/google/google-drive/GoogleDriveConsentResponse";
import Organization from "pages/organization/Organization";
import Invite from "pages/invite/Invite";
import Calendar from "pages/home/calendar/Calendar";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<Error />}>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/bookmarked-calls" element={<UserBookmarkedCalls />} />
        <Route
          path="/calls/guidelines-assistant"
          element={<GuidelinesAssistant />}
        />
        <Route path="/organization" element={<Organization />} />
        <Route path="/invite" element={<Invite />} />

        {/* <Route path="/calls/search-calls" element={<SearchCalls />} /> */}
        <Route path="/settings/profile" element={<Profile />} />
        <Route path="/settings/billing" element={<Billing />} />
        <Route path="/settings/billing/success" element={<Success />} />
        <Route path="/settings/integrations" element={<Integrations />} />

        <Route path="/project/:projectId" element={<Project />} />
        <Route
          path="/project/:projectId/ideation-space"
          element={<ResearchIdea />}
        />
        <Route
          path="/project/:projectId/ideation-space/background-material"
          element={<BackgroundMaterial />}
        />
        <Route
          path="/project/:projectId/ideation-space/brainstorm"
          element={<Brainstorm />}
        />
        <Route
          path="/project/:projectId/ideation-space/brainstorm-session/:sessionId"
          element={<BrainstormSession />}
        />

        <Route
          path="/project/:projectId/grant-space/recommended-calls"
          element={<RecommendedCalls />}
        />
        <Route
          path="/project/:projectId/grant-space/search-calls"
          element={<SearchCalls />}
        />

        <Route
          path="/project/:projectId/grant-space/bookmarks"
          element={<BookmarkedCalls />}
        />

        <Route
          path="/project/:projectId/write-space"
          element={<ListTemplates />}
        />
        <Route
          path="/project/:projectId/write-space/:templateId"
          element={<DocumentTemplate />}
        />
        <Route
          path="/project/:projectId/review-space"
          element={<ListReviews />}
        />
        <Route
          path="/project/:projectId/review-space/:fileId"
          element={<ProposalReview />}
        />
        <Route
          path="/project/:projectId/admin-space/selected-call"
          element={<SelectCall />}
        />

        <Route
          path="/project/:projectId/admin-space/action-items"
          element={<ActionItems />}
        />
        <Route
          path="/project/:projectId/admin-space/document-checklist"
          element={<DocumentChecklist />}
        />
        <Route
          path="/google/oauth2callback"
          element={<GoogleDriveConsentResponse />}
        />
      </Route>
      <Route element={<LoginLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/delete" element={<DeleteAccount />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/identities/confirm" element={<ConfirmEmail />} />
        <Route path="/identities/reset_password" element={<ResetPassword />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_PROJECT_ID}>
      <SWRConfig
        value={{
          // revalidateOnMount: false,
          revalidateIfStale: false,
          revalidateOnFocus: false,
          refreshInterval: 0,
          shouldRetryOnError: false,
        }}
      >
        <RouterProvider router={router} />
      </SWRConfig>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
