import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { downgrade_plan, preview_billing_changes } from "api/billing";
import { LoadingSpinner } from "components";
import { useIdempotencyKey } from "stores/useIdempotencyKey";
import useSWRMutation from "swr/mutation";
import { v4 as uuidv4 } from "uuid";

export default function DowngradeToPro({ pro_stripe_id, numberOfMembers }) {
  const {
    data: hasDowngraded,
    trigger: downgradeToPro,
    isMutating: isDowngradingToPro,
  } = useSWRMutation("downgrade_to_pro", () =>
    downgrade_plan({
      stripe_price_id: pro_stripe_id,
      idempotency_key: uuidv4(),
    })
  );
  return (
    <Dialog>
      <DialogTrigger className="text-sm border-1 rounded-md p-1 hover:border-fuchsia">
        Downgrade to Pro
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Downgrade to Pro</DialogTitle>
          <DialogDescription>
            Are you sure you want to downgrade to pro?
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end">
          {hasDowngraded ? (
            <button className="btn btn-fuchsia">Downgraded!</button>
          ) : (
            <button
              disabled={numberOfMembers > 1 || isDowngradingToPro}
              className="btn btn-fuchsia"
              onClick={downgradeToPro}
            >
              Downgrade {isDowngradingToPro && <LoadingSpinner />}
            </button>
          )}
        </div>
        {numberOfMembers > 1 && (
          <span className="text-red-500 text-sm">
            You can't downgrade to pro if you have other members in your
            organization.
          </span>
        )}
      </DialogContent>
    </Dialog>
  );
}
