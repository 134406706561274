import { ErrorPage, LoadingPage, Title } from "components";
import {
  delete_project,
  get_profile,
  get_project,
  update_project_name,
} from "api";
import { useNavigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";

import { MdDelete } from "react-icons/md";
import IdeaCard from "./project-cards/IdeaCard";
import ShareProject from "./share-project/ShareProject";
import useSWRMutation from "swr/mutation";
import AdminCard from "./project-cards/AdminCard";
import GrantCard from "./project-cards/GrantCard";
import { PageHelp } from "@/components/styled/pageHelp";
import WriteCard from "./project-cards/WriteCard";
import ReviewCard from "./project-cards/ReviewCard";

import EditProjectTitle from "./EditProjectTitle";
import { useIsEditor } from "@/hooks/use-is-editor";
import { FaEllipsisVertical } from "react-icons/fa6";
import DeleteProject from "./DeleteProject";

export default function Project() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  if (isProjectLoading || isEditorLoading) return <LoadingPage />;
  if (projectError || editorError) return <ErrorPage />;

  return (
    <div className="bg-white flex flex-col rounded-lg gap-y-4 w-full h-full">
      <div className="flex justify-between w-full h-fit">
        <EditProjectTitle
          projectId={projectId}
          title={project.name}
          hasEditorAccess={hasEditorAccess}
        />

        <div className="flex items-center gap-x-4">
          <ShareProject
            hasEditorAccess={hasEditorAccess}
            projectId={projectId}
            users={project.users}
            project_name={project.name}
          />
          {hasEditorAccess && <DeleteProject />}
          <PageHelp
            title="Dashboard"
            content={
              <div className="flex flex-col gap-y-4">
                <div>
                  A project is a collaborative space for you to work on your
                  grant proposal. Think of a project as one main idea that you
                  will use to apply to one grant application. Each project has a
                  few different spaces for you to work in.
                </div>
                <div>
                  <h2 className="font-semibold">Ideation Space</h2>
                  This is the area for you to help us understand what your idea
                  is, along with being able to brainstorm and really refine what
                  you want to research.
                </div>
                <div>
                  <h2 className="font-semibold">Grant Space</h2>
                  This is the space for you to see which grants are available
                  and to select a grant that you would like to apply to.
                </div>
                <div>
                  <h2 className="font-semibold">Admin Space</h2>
                  The Admin Space is a way for you to keep track of everything
                  that you and your collaborators need to do and all of the
                  required files that you have to hand in.
                </div>
                <div>
                  Now each project is meant to be collaborative space so
                  remember that you can share them with your collaborators as
                  well.
                </div>
              </div>
            }
          />
        </div>
      </div>

      <div className="flex flex-row flex-wrap gap-4 p-4">
        <IdeaCard projectId={projectId} />
        <GrantCard projectId={projectId} />
        <WriteCard projectId={projectId} />
        <ReviewCard projectId={projectId} />
        <AdminCard projectId={projectId} />
      </div>
    </div>
  );
}
