import { useSettings } from "@/hooks/use-settings";
import {
  get_member_list,
  get_organization_info,
  get_pending_invites_list,
} from "api/organization";
import { ErrorPage, LoadingPage } from "components";
import Invite from "components/features/organization/invite/Invite";
import List from "components/features/organization/list/List";
import OrgInfo from "components/features/organization/org_info/OrgInfo";
import { useMemo } from "react";
import useSWR from "swr";

export default function Organization() {
  const { settings: settings } = useSettings();
  const {
    data: orgInfo,
    isLoading: isGettingOrgInfo,
    error: orgInfoError,
  } = useSWR("org_info", get_organization_info);
  const {
    data: allMemberList,
    isLoading: isGettingMemberList,
    error: memberListError,
  } = useSWR("org_member_list", get_member_list);

  if (isGettingOrgInfo || isGettingMemberList) return <LoadingPage />;
  if (orgInfoError || memberListError) return <ErrorPage />;

  // console.log(invites);
  const memberList = allMemberList.filter((member) => member.email);

  return (
    <div className="flex flex-col gap-4">
      {!orgInfo.organization_billing && (
        <div className="flex items-center justify-center w-full h-full fixed bg-black -translate-y-9 -translate-x-8 z-[1] bg-opacity-60">
          <div className="bg-white rounded-lg max-w-lg p-2">
            <span className="font-semibold">
              Organization features are locked.
            </span>{" "}
            To use this feature, please upgrade your plan to an Organization or
            Enterprise tier.
          </div>
        </div>
      )}
      <OrgInfo {...orgInfo} />
      <List
        org_relationship={settings.org_relationship}
        memberList={memberList}
        num_members={orgInfo.num_members}
        total_seats={orgInfo.total_seats}
      />
    </div>
  );
}
