import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { preview_billing_changes, upgrade_plan } from "api/billing";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { useIdempotencyKey } from "stores/useIdempotencyKey";
import useSWRMutation from "swr/mutation";
import { v4 as uuidv4 } from "uuid";
export default function UpgradeToOrgPlan({ org_stripe_id, hasScheduledPlans }) {
  const [numSeats, setNumSeats] = useState(1);
  const [isPreviewingChanges, setIsPreviewingChanges] = useState(false);
  // const {idempotencyKey} = useIdempotencyKey();
  const [isForceUpgrade, setIsForceUpgrade] = useState(false);

  const {
    data: invoice,
    trigger: previewChanges,
    isMutating: isGeneratingPreview,
  } = useSWRMutation(`preview_changes/${numSeats}`, () =>
    preview_billing_changes({
      idempotency_key: uuidv4(),
      stripe_price_id: org_stripe_id,
      quantity: numSeats,
    })
  );

  const {
    data: hasUpgraded,
    trigger: upgradeToOrg,
    isMutating: isUpgrading,
  } = useSWRMutation("upgrade_to_org", () =>
    upgrade_plan({
      stripe_price_id: org_stripe_id,
      quantity: numSeats,
      idempotency_key: uuidv4(),
      proration_date: invoice.proration_date
    })
  );

  return (
    <Dialog onOpenChange={() => setIsPreviewingChanges(false)}>
      <DialogTrigger className="text-sm border-1 rounded-md p-1 hover:border-fuchsia">
        Upgrade To Org
      </DialogTrigger>
      <DialogContent className="max-w-screen-md">
        <DialogHeader>
          <DialogTitle>Upgrade to Org Plan</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex items-center gap-x-4">
          <span className="font-semibold whitespace-nowrap">
            Number of Seats
          </span>
          <Input
            type="number"
            value={numSeats}
            min={1}
            onChange={(e) => setNumSeats(e.target.value)}
          />
        </div>
        <div className="flex justify-end">
          <button
            disabled={isGeneratingPreview}
            onClick={() => {
              previewChanges();
              setIsPreviewingChanges(true);
            }}
            className="btn btn-fuchsia"
          >
            Preview Updates {isGeneratingPreview && <LoadingSpinner />}
          </button>
        </div>
        {invoice && (
          <>
            <div className="border-t-1 py-4">
              <ul>
                {invoice.upcoming_invoice.lines.data.map(
                  ({ amount, description }) => (
                    <li className="text-right">
                      <span className="text-muted-foreground text-sm">
                        {description}
                      </span>{" "}
                      <span className="text-lg font-mono">{amount / 100}</span>
                    </li>
                  )
                )}
                <li className="text-right flex justify-end">
                  <div className="border-t-1 w-fit">
                    <span className="text-muted-foreground text-sm">
                      Total Due
                    </span>{" "}
                    <span className="text-lg font-mono">
                      {invoice.upcoming_invoice.amount_due / 100}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            {hasScheduledPlans && (
              <>
                <Checkbox
                  checked={isForceUpgrade}
                  onCheckedChange={setIsForceUpgrade}
                />{" "}
                You currently have scheduled updates for the next billing
                period. Are you sure you want to cancel your updates?
              </>
            )}
            <div className="flex justify-end">
              <button
                className="btn btn-fuchsia"
                onClick={upgradeToOrg}
                disabled={isUpgrading || (hasScheduledPlans && !isForceUpgrade)}
              >
                Upgrade to new plan
              </button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
