import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import { format } from "date-fns";

export default function ActionItemEvent({ eventInfo }) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Action Item Due</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <div className="flex justify-end">
        <Link
          to={`/project/${eventInfo?.project_id}/admin-space/action-items`}
          className="btn btn-fuchsia"
        >
          Go to Project
        </Link>
      </div>
      <ScrollArea className="max-h-96">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">Task</TableCell>
              <TableCell>{eventInfo?.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Description</TableCell>
              <TableCell>{eventInfo?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Due</TableCell>
              <TableCell>
                {eventInfo?.date && format(new Date(eventInfo?.date), "E PP")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Project</TableCell>
              <TableCell>{eventInfo?.project_name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ScrollArea>
    </>
  );
}
