import { get_settings } from "api/settings";
import { useCallback, useMemo } from "react";
import useSWR from "swr";

export function useSettings() {
  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  const hasProjectEditorAccess = useCallback(
    (project) => {
      if (isGettingSettings) return false;
      const user = project.users.find(
        (user) => user.user_id === settings.user_id
      );
      if (!user) return false;

      return user.relationship !== "viewer";
    },
    [settings]
  );

  return { settings, isGettingSettings, settingsError, hasProjectEditorAccess };
}
