/* 
    Name: http-common.js
    Purpose: Set up axios requests with automatic caching, refreshing,
             and re-running of failed requests after refreshing.
*/

import axios from "axios";
import { useAuthStore } from "stores/useAuthStore";
import { refresh_token } from "./auth";
import { mutate } from "swr";

// Set up uncached axios request
// On local development, we use react-script's proxy (set in package.json)
// On production, we set the base URL to include /api and let nginx do the proxy on server side
const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "" : "/api/",
  withCredentials: true,
});

export const cancelToken = axios.CancelToken.source();

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.config.url === "/auth/refresh") {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (originalRequest.url === "/user/login") {
      return Promise.reject(error);
    }

    // If error is an authentication error, try to refresh token and redo api request
    // We set expired JWT error to code 418
    if (error.response?.status === 418 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(new Error(err));
          });
      }

      originalRequest._retry = true;

      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        let refreshToken = useAuthStore.getState().refreshToken;
        refresh_token({ refreshToken: refreshToken })
          .then((response) => {
            // TODO: Check if need to set refresh_token here
            const { refresh_token } = response;
            useAuthStore.getState().setRefreshToken(refresh_token);
            isRefreshing = false;
            processQueue(null);
            return resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            // If refreshing token fails, clear authStore and redirect to /login
            isRefreshing = false;
            useAuthStore.getState().logout();
            useAuthStore.persist.clearStorage();
            mutate(() => true, undefined, { revalidate: true });
            processQueue(err);
            // window.location.href = "/login";
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
