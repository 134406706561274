import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";

export default function AddACall() {
  return (
    <Dialog>
      <DialogTrigger>Add a call</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add your own call</DialogTitle>
          <DialogDescription>
            Did our system miss a call? Add your own call to keep track!
          </DialogDescription>
        </DialogHeader>
        <div>
          <div>Enter URL</div>
          <Input />
        </div>
        OR
        <div>
          <div>Title</div>
          <Input />
          <div>Agency Name</div>
          <Input />
          <div>Close Date</div>
          <Input />
          <div>Description</div>
          <Input />
          <div>Award Floor</div>
          <Input />
          <div>Elligible Applicants</div>
          <Input />
          <div>Funding Instrument Type</div>
          <Input />
          ---
          <div>Posted Date</div>
          <Input />
          <div>Fiscal Year</div>
          <Input />
          <div>URL</div>
          <Input />
        </div>
      </DialogContent>
    </Dialog>
  );
}
