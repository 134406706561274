import { FaExternalLinkAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {
  delete_paper,
  get_gcp_paper_link,
  update_paper_authors,
  update_paper_title,
  update_paper_abstract,
} from "api";
import { LoadingSpinner } from "components";
import useSWRMutation from "swr/mutation";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import EditPaper from "./EditPaper";
import DeletePaper from "./DeletePaper";
import useSWR from "swr";

export default function SelectedPaper({
  projectId,
  authors,
  external_paper_url,
  year_published,
  name,
  initial_text,
  paper_id,
  mutatePapers,
}) {
  const [isGetGCPError, setIsGetGCPError] = useState(false);

  const [isEditingTab, setIsEditingTab] = useState(false);
  const [isDeletingTab, setIsDeletingTab] = useState(false);

  console.log(external_paper_url);

  const { data: paper } = useSWR(
    external_paper_url ? null : `get_gcp_paper?${projectId}/${paper_id}`,
    () => get_gcp_paper_link({ project_id: projectId, paper_id: paper_id })
  );

  // // If there is no external_paper_url, try to generate a GCP link
  // const handleGetGCPLink = async () => {
  //   try {
  //     setIsGetGCPError(false);
  //     const { url } = await get_gcp_paper_link({
  //       project_id: projectId,
  //       paper_id: paper_id,
  //     });
  //     window.open(url, "_blank");
  //   } catch (e) {
  //     setIsGetGCPError(true);
  //   }
  // };

  return (
    <Dialog
      onOpenChange={() => {
        setTimeout(() => {
          setIsDeletingTab(false);
          setIsEditingTab(false);
        }, 100);
      }}
    >
      <DialogTrigger asChild>
        <div className="flex flex-col border-[1px] rounded-lg p-4 hover:border-fuchsia text-left hover:cursor-pointer">
          <div className="flex flex-row w-full">
            <div className="gap-y-1 flex flex-col w-full">
              <div className="flex w-full group">
                <div className="flex flex-col grow w-full">
                  <div className="flex flex-row">
                    <span className="font-semibold text-xl">{name}</span>
                  </div>
                  <div className="flex gap-x-2">
                    {authors ? authors.join(", ") : ""}
                  </div>
                  {isGetGCPError && (
                    <span className="text-red-500">
                      There was an issue getting the link. Please try again.
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div className="text-base">
                  <div>
                    {initial_text
                      ? initial_text?.substring(0, 300) + "..."
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-3xl max-h-screen overflow-y-scroll">
        {isEditingTab ? (
          <EditPaper
            projectId={projectId}
            paper_id={paper_id}
            name={name}
            initial_text={initial_text}
            authors={authors}
            handleOnClickCancel={() => setIsEditingTab(false)}
          />
        ) : isDeletingTab ? (
          <DeletePaper
            projectId={projectId}
            paper_id={paper_id}
            handleOnClickCancel={() => setIsDeletingTab(false)}
          />
        ) : (
          <>
            <div className="flex flex-col gap-y-2">
              <DialogHeader>
                <DialogTitle></DialogTitle>
                <DialogDescription></DialogDescription>
              </DialogHeader>
              <div className="flex gap-x-2 mb-4">
                <button
                  onClick={() => setIsEditingTab(true)}
                  className="btn-secondary"
                >
                  Edit
                </button>
                <button
                  onClick={() => setIsDeletingTab(true)}
                  className="btn-secondary"
                >
                  Remove
                </button>
                {external_paper_url && (
                  <a
                    href={external_paper_url}
                    rel="noreferrer"
                    target="_blank"
                    className="btn-secondary hover:underline hover:opacity-60"
                  >
                    Open Link
                    <span className="inline-flex">
                      <FaExternalLinkAlt className="w-4 h-4" />
                    </span>
                  </a>
                )}
                {paper?.url && (
                  <a
                    href={paper.url}
                    rel="noreferrer"
                    target="_blank"
                    className="btn-secondary hover:underline hover:opacity-60"
                  >
                    Open Link
                    <span className="inline-flex">
                      <FaExternalLinkAlt className="w-4 h-4" />
                    </span>
                  </a>
                )}
              </div>
              <span className="text-xl font-semibold">{name}</span>
              <div className="flex gap-x-2">
                {authors ? authors.join(", ") : ""}
              </div>
              <div className="flex">
                <div>{initial_text}</div>
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
