import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LoadingSpinner } from "components";
import { verifyUser } from "api/auth";
import useSWR from "swr";

export default function ConfirmEmail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const org_invite_code = searchParams.get("org_invite_code");



  const { isLoading, error: isError } = useSWR(
    "verify_user",
    async () =>
      await verifyUser({
        verification_code: token,
        org_invite_code: org_invite_code,
      }),
    {
      refreshInterval: false,
      shouldRetryOnError: false,
      onErrorRetry: () => {
        return;
      },
      onSuccess: () => {
        console.log("success");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      },
      onError: () => {
        console.log("error");
      },
    }
  );

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 bg-white rounded-b-2xl flex flex-col gap-y-4 px-4">
          <span className="font-bold">Confirming Email</span>
          <div className="flex justify-center">
            {isLoading ? (
              <LoadingSpinner className="text-fuchsia h-20 w-20" />
            ) : isError ? (
              <p>There was an unexpected error. Please try again.</p>
            ) : (
              <p>
                Successfully verified, redirecting to login in a few moments...
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
