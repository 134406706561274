import axiosInstance from "./axiosInstance";

export const get_organization_info = async () => {
  const response = await axiosInstance({
    url: "/org/info",
    method: "GET",
  });
  return response.data;
};

export const update_organization_name = async ({ new_name }) => {
  const response = await axiosInstance({
    url: "org/name/update",
    method: "PUT",
    data: {
      new_name: new_name,
    },
  });
  return response.data;
};

export const get_member_list = async () => {
  const response = await axiosInstance({
    url: "/org/members/list",
    method: "GET",
  });
  return response.data;
};

export const get_pending_invites_list = async () => {
  const response = await axiosInstance({
    url: "org/members/list",
    method: "GET",
    params: { pending: true },
  });
  return response.data;
};

/**
 *
 * @invites {*} List of objects with value of email and role
 */
export const invite_member_to_organization = async ({ invites }) => {
  const response = await axiosInstance({
    url: "org/members/add",
    method: "POST",
    data: {
      invites: invites,
    },
  });
  return response.data;
};

export const create_shareable_link = async ({ role }) => {
  const response = await axiosInstance({
    url: "org/members/add/invite_code",
    method: "POST",
    data: {
      role: role,
    },
  });
  return response.data;
};

export const pending_invite_send_email = async ({ code }) => {
  const response = await axiosInstance({
    url: "org/members/pending",
    method: "POST",
    data: {
      code: code,
    },
  });
  return response.data;
};

export const remove_invite_code = async ({ code }) => {
  const response = await axiosInstance({
    url: "org/members/pending",
    method: "DELETE",
    data: {
      code: code,
    },
  });
  return response.data;
};

export const remove_member_from_organization = async ({ user_id }) => {
  const response = await axiosInstance({
    url: "org/members/remove",
    method: "DELETE",
    data: {
      user_id: user_id,
    },
  });
  return response.data;
};

export const get_invite_code_info = async ({ code }) => {
  const response = await axiosInstance({
    url: "org/invite/info",
    method: "GET",
    params: {
      code: code,
    },
  });
  return response.data;
};

export const accept_org_invite = async ({ invite_code, force_join }) => {
  const response = await axiosInstance({
    url: "org/join",
    method: "POST",
    data: {
      invite_code: invite_code,
      force_join: force_join,
    },
  });
  return response.data;
};

export const update_member_role = async ({ user_id, role }) => {
  const response = await axiosInstance({
    url: "org/members/manage",
    method: "PUT",
    data: {
      user_id: user_id,
      role: role,
    },
  });
  return response.data;
};
