import { Title } from "components";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useState } from "react";
import Chat from "./Chat";

export default function ReviewerFeedback({ outlines, fileId }) {
  const [reviewOption, setReviewOption] = useState("general");
  // const [chatReviewer, setChatReviewer] = useState("general");

  const handleValueChange = (value) => {
    setReviewOption(value);
  };
  return (
    <div>
      <Title className="text-2xl" value="Overview" />
      <Select value={reviewOption} onValueChange={handleValueChange}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="general">General</SelectItem>
          <SelectItem value="reviewer_1">Reviewer 1</SelectItem>
          <SelectItem value="reviewer_3">Reviewer 3</SelectItem>
          <SelectItem value="reviewer_2" disabled>
            Reviewer 2 - Out for Lunch
          </SelectItem>
        </SelectContent>
      </Select>
      {/* Render a different chat for each reviewer */}

      <div hidden={reviewOption !== "general"}>
        <Chat fileId={fileId} reviewer={"general"} />
      </div>

      <div hidden={reviewOption !== "reviewer_1"}>
        <Chat fileId={fileId} reviewer={"reviewer_1"} />
      </div>
      <div hidden={reviewOption !== "reviewer_3"}>
        <Chat fileId={fileId} reviewer={"reviewer_3"} />
      </div>

      {Object.entries(outlines[reviewOption]).map(([key, value]) => (
        <div key={key}>
          {/* <Title className="mt-4 text-2xl capitalize" value={key} /> */}
          <span className="font-semibold capitalize mt-4">
            {key === "bullets"
              ? "Summary"
              : key === "questions"
              ? "Questions to Consider"
              : key.replaceAll("_", " ")}
          </span>
          <ul className="list-outside pl-4 list-disc text-sm">
            {value.map((m, i) => (
              <li key={i}>{m}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
