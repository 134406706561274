import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import { ReactComponent as InitiumIcon } from "assets/initium-logos/brandmark-colored.svg";
import { useMemo, useState } from "react";

export default function InitiumChatMessage({
  agency_name,
  text,
  references,
  citations,
}) {
  const tabs = references?.map(({ id, text }, i) => ({
    value: id,
    label: i + 1,
    content: text,
  }));

  const [isReferencesOpen, setIsReferencesOpen] = useState(false);
  const [referenceTabValue, setReferenceTabValue] = useState(
    tabs?.length ? tabs[0].value : null
  );

  const transformedJSX = useMemo(() => {
    let jsxArray = [];
    let newText = text;
    for (let i = citations.length - 1; i >= 0; i--) {
      jsxArray.unshift(newText.slice(citations[i].end));

      const citeInserts = citations[i].document_ids.map((id) => (
        <button
          className="hover:text-fuchsia text-xs -translate-y-1"
          onClick={() => {
            setIsReferencesOpen(true);
            setReferenceTabValue(id);
          }}
        >
          &#91;{tabs.findIndex((tab) => tab.value === id) + 1}&#93;
        </button>
      ));
      citeInserts.forEach((cite) => jsxArray.unshift(cite));
      newText = newText.slice(0, citations[i].end);
    }
    jsxArray.unshift(newText);
    return jsxArray;
  }, [citations]);

  return (
    <div className="w-full flex gap-x-2 items-end pb-8">
      <div className="rounded-full bg-pastel-blue border-2 h-fit w-fit translate-y-1/2">
        <InitiumIcon className="w-8 h-8" />
      </div>
      <div className="bg-pastel-blue flex flex-col px-4 py-1 rounded-2xl rounded-es-none text-sm max-w-lg">
        <div className="border-b-1 border-offblack font-semibold text-base">
          {agency_name} Agency Guideline
        </div>
        <div>
          {transformedJSX.map((jsx, index) => (
            <span key={index}>{jsx}</span>
          ))}
        </div>
        {references && (
          <>
            <div className="flex justify-end">
              <button
                className="underline text-base hover:text-fuchsia"
                onClick={() => setIsReferencesOpen((p) => !p)}
              >
                &#91;References&#93;
              </button>
            </div>
            {isReferencesOpen && (
              <Tabs
                value={referenceTabValue}
                onValueChange={setReferenceTabValue}
              >
                <TabsList className="p-0">
                  {tabs.map(({ value, label }) => (
                    <TabsTrigger
                      value={value}
                      key={value}
                      className="px-2 underline data-[state=active]:text-fuchsia data-[state=active]:border-1 border-offblack rounded-md"
                    >
                      {label}
                    </TabsTrigger>
                  ))}
                </TabsList>
                {tabs.map(({ value, content }) => (
                  <TabsContent value={value} key={value}>
                    {content}
                  </TabsContent>
                ))}
              </Tabs>
            )}
          </>
        )}
      </div>
    </div>
  );
}
