import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { add_new_review } from "api/review_space";
import { LoadingSpinner } from "components";
import { Upload } from "lucide-react";
import { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import { FaCloudUploadAlt } from "react-icons/fa";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

import agent_backgrounds from "@/types/agent_backgrounds";

export default function UploadFromLocal({ projectId, org_tier }) {
  const closeButtonRef = useRef(null);

  const [reviewer1Background, setReviewer1Background] = useState("");
  const [reviewer3Background, setReviewer3Background] = useState("");

  const {
    data: fileData,
    isMutating: isFileUploading,
    error: isFileUploadError,
    trigger: uploadFile,
    reset: resetFile,
  } = useSWRMutation(
    `add_new_review/${projectId}`,
    (key, { arg }) => {
      return add_new_review(arg);
    },
    {
      onSuccess: () => {
        resetFile();
        closeButtonRef?.current.click();
        mutate(`list_reviews/${projectId}`);
      },
    }
  );

  const { acceptedFiles, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      accept: { "application/pdf": [".pdf"], "application/msword": [".docx"] },
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
    });

  const onUploadFile = () => {
    const { name, type } = acceptedFiles[0];
    uploadFile({
      project_id: projectId,
      file_type: type,
      file_name: name,
      file: acceptedFiles[0],
    });
  };

  if (org_tier === "starter")
    return (
      <Tooltip>
        <TooltipTrigger
          className="btn-fuchsia text-sm"
          disabled={org_tier === "starter"}
        >
          <Upload className="h-4 w-4" />
          Upload file
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please upgrade to pro to use this feature.
        </TooltipContent>
      </Tooltip>
    );
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="btn-fuchsia text-sm">
          <Upload className="h-4 w-4" />
          Upload file
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload a file</DialogTitle>
          <DialogDescription>
            Processing may take around 5-10 minutes.
          </DialogDescription>
        </DialogHeader>
        <>
          <DialogClose className="hidden" ref={closeButtonRef} />
          {isFileUploading ? (
            <div
              className={
                "w-full p-8 border-4 border-dashed rounded-lg flex gap-x-4 items-center justify-center"
              }
            >
              <LoadingSpinner />
              <p className="text-xl">Uploading</p>
            </div>
          ) : (
            <>
              <Select
                value={reviewer1Background}
                onValueChange={setReviewer1Background}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a Background for Reviewer 1" />
                </SelectTrigger>
                <SelectContent>
                  {agent_backgrounds.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>{label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Select
                value={reviewer3Background}
                onValueChange={setReviewer3Background}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a Background for Reviewer 3" />
                </SelectTrigger>
                <SelectContent>
                  {agent_backgrounds.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>{label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div
                className={
                  "w-full p-8 border-4 border-dashed rounded-lg " +
                  (isDragActive ? "border-fuchsia" : "border-zinc-200")
                }
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {acceptedFiles.length ? (
                  <div className="flex flex-col items-center justify-center h-40">
                    <FaCloudUploadAlt className="w-20 h-20" />
                    <p className="text-xl">{acceptedFiles[0].path}</p>
                    <span className="h-6"></span>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center h-40">
                    <FaCloudUploadAlt className="w-20 h-20" />
                    <p className="text-xl">Drag and drop a PDF or DOCX here</p>
                    <p>or</p>
                    <button onClick={open} className="btn-offblack text-xl">
                      Click to browse files
                    </button>
                  </div>
                )}
              </div>
              <div className="flex justify-center">
                <button
                  disabled={
                    !reviewer1Background ||
                    !reviewer3Background ||
                    acceptedFiles.length === 0
                  }
                  className="btn-fuchsia text-xl"
                  onClick={onUploadFile}
                >
                  Upload File
                </button>
              </div>
            </>
          )}
        </>
      </DialogContent>
    </Dialog>
  );
}
