import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  pending_invite_send_email,
  remove_invite_code,
} from "api/organization";
import { EllipsisVerticalIcon } from "lucide-react";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function PendingMemberOption({ code, can_send_email, email }) {
  const inviteLink =
    process.env.REACT_APP_URL + `invite?org_invite_code=${code}`;

  const [isResendEmailDialogOpen, setIsResendEmailDialogOpen] = useState(false);
  const [isGetLinkDialogOpen, setIsGetLinkDialogOpen] = useState(false);
  const [isRemoveInviteDialogOpen, setIsRemoveInviteLinkDialogOpen] =
    useState(false);

  const [hasUserCopiedLink, setHasUserCopiedLink] = useState(false);

  const { trigger: removeInvite } = useSWRMutation(
    "remove_invite",
    () => remove_invite_code({ code: code }),
    {
      onSuccess: () =>
        mutate(
          "org_member_list",
          (data) => data.filter((invite) => invite.email !== email),
          { revalidate: false }
        ),
    }
  );

  const { trigger: sendEmail } = useSWRMutation("send_email", () =>
    pending_invite_send_email({ code: code })
  );

  const handleDeleteInvite = () => {
    removeInvite();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    setHasUserCopiedLink(true);
  };

  const handleSendEmail = () => {
    sendEmail();
  };
  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="hover:text-fuchsia">
          <EllipsisVerticalIcon />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {can_send_email && (
            <DropdownMenuItem onClick={() => setIsResendEmailDialogOpen(true)}>
              Resend Email
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={() => setIsGetLinkDialogOpen(true)}>
            Get Link
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-red-500 focus:text-red-500"
            onClick={() => setIsRemoveInviteLinkDialogOpen(true)}
          >
            Remove Invite
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        open={isResendEmailDialogOpen}
        onOpenChange={setIsResendEmailDialogOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Resend Email</DialogTitle>
            <DialogDescription>
              Resend the email to {email} by clicking the send button below.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end">
            <button className="btn btn-fuchsia" onClick={handleSendEmail}>
              Send
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={isGetLinkDialogOpen} onOpenChange={setIsGetLinkDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Copy link to clipboard</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <p className="text-xs">{inviteLink}</p>
          <div className="flex justify-end">
            <button className="btn btn-fuchsia" onClick={handleCopyLink}>
              {hasUserCopiedLink ? "Copied!" : "Copy"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isRemoveInviteDialogOpen}
        onOpenChange={setIsRemoveInviteLinkDialogOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Remove Invite</DialogTitle>
            <DialogDescription>
              Are you sure you want to remove {email}'s invite?
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end">
            <button
              className="text-red-500 hover:underline"
              onClick={handleDeleteInvite}
            >
              Remove Invite
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
