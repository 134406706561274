import { Navigate, Outlet } from "react-router-dom";

import InitiumLogo from "assets/initium-logos/primary-horizontal-colored-black.svg";
import { useAuthStore } from "stores/useAuthStore";
import { mutate } from "swr";
import { useEffect } from "react";

export default function LoginLayout() {
  const { refreshToken } = useAuthStore();
  // useEffect(() => {
  //   mutate(() => true, undefined, { revalidate: true });
  // }, []);
  if (refreshToken) return <Navigate to={"/"} />;

  return (
    <div className="h-screen w-screen bg-offwhite flex justify-center">
      <div className="w-full flex flex-col justify-between">
        <img
          className="w-40 aspect-auto"
          src={InitiumLogo}
          alt="Initium Logo"
        />
        <Outlet />
        <div className="h-20"></div>
      </div>
    </div>
  );
}
