import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  downgrade_plan,
  preview_billing_changes,
  upgrade_plan,
} from "api/billing";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { useIdempotencyKey } from "stores/useIdempotencyKey";
import { v4 as uuidv4 } from "uuid";

import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function ChangeNumberOfSeats({
  numberOfMembers,
  numberOfSeats,
  org_stripe_id,
}) {
  const [tempNumberOfSeats, setTempNumberOfSeats] = useState(numberOfSeats);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [isDowngrading, setIsDowngrading] = useState(false);

  const { idempotencyKey } = useIdempotencyKey();

  const {
    data: invoice,
    trigger: previewChanges,
    isMutating: isGeneratingPreview,
  } = useSWRMutation(`preview_changes/${tempNumberOfSeats}`, () =>
    preview_billing_changes({
      idempotency_key: uuidv4(),
      stripe_price_id: org_stripe_id,
      quantity: tempNumberOfSeats,
    })
  );

  const {
    data: hasUpgraded,
    trigger: upgradeNumberOfSeats,
    isMutating: isUpgradingSeats,
  } = useSWRMutation(
    `update_seats`,
    () =>
      upgrade_plan({
        stripe_price_id: org_stripe_id,
        idempotency_key: uuidv4(),
        quantity: tempNumberOfSeats,
        proration_date: invoice.proration_date,
      }),
    { onSuccess: () => mutate("get_customer_plan") }
  );
  const {
    data: hasDowngraded,
    trigger: downgradeNumberOfSeats,
    isMutating: isDowngradingSeats,
  } = useSWRMutation(
    "downgrade_seats",
    () =>
      downgrade_plan({
        stripe_price_id: org_stripe_id,
        idempotency_key: uuidv4(),
        quantity: tempNumberOfSeats,
      }),
    {
      onSuccess: () => mutate("get_customer_plan"),
    }
  );
  const handleStartUpdating = () => {
    if (tempNumberOfSeats < numberOfSeats) {
      setIsDowngrading(true);
    } else {
      setIsUpgrading(true);
      previewChanges(idempotencyKey);
    }
  };

  return (
    <Dialog
      onOpenChange={() => {
        setIsUpgrading(false);
        setIsDowngrading(false);
      }}
    >
      <DialogTrigger className="text-sm border-1 rounded-md p-1 hover:border-fuchsia">
        Update Seats
      </DialogTrigger>
      <DialogContent className="max-w-screen-md">
        {isDowngrading ? (
          <>
            <DialogHeader>
              <DialogTitle>Downgrade your plan</DialogTitle>
              <DialogDescription>
                Downgrading your plan will update your next billing period. Are
                you sure you want to downgrade your plan?
              </DialogDescription>
            </DialogHeader>
            <span className="text-right">
              Downgrade to {tempNumberOfSeats} seat
              {tempNumberOfSeats > 1 && "s"}?
            </span>
            <div className="flex justify-end">
              {hasDowngraded ? (
                <button className="btn btn-fuchsia">Downgraded!</button>
              ) : (
                <button
                  className="btn btn-fuchsia"
                  onClick={downgradeNumberOfSeats}
                  disabled={
                    tempNumberOfSeats < numberOfMembers || isDowngradingSeats
                  }
                >
                  Downgrade {isDowngradingSeats && <LoadingSpinner />}
                </button>
              )}
            </div>
          </>
        ) : isUpgrading ? (
          <>
            <DialogHeader>
              <DialogTitle>Upgrade your plan</DialogTitle>
              <DialogDescription>
                By upgrading your plan, your current billing period will be
                updated.
              </DialogDescription>
              {isGeneratingPreview ? (
                <LoadingSpinner />
              ) : (
                invoice && (
                  <>
                    <div className="border-t-1 py-4">
                      <ul>
                        {invoice.upcoming_invoice.lines.data.map(
                          ({ amount, description }) => (
                            <li className="text-right">
                              <span className="text-muted-foreground text-sm">
                                {description}
                              </span>{" "}
                              <span className="text-lg font-mono">
                                {amount / 100}
                              </span>
                            </li>
                          )
                        )}
                        <li className="text-right flex justify-end">
                          <div className="border-t-1 w-fit">
                            <span className="text-muted-foreground text-sm">
                              Total Due
                            </span>{" "}
                            <span className="text-lg font-mono">
                              {invoice.upcoming_invoice.amount_due / 100}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="flex justify-end">
                      {hasUpgraded ? (
                        <button className="btn btn-fuchsia">Upgraded!</button>
                      ) : (
                        <button
                          className="btn btn-fuchsia"
                          onClick={upgradeNumberOfSeats}
                          disabled={isUpgradingSeats}
                        >
                          Upgrade to new plan{" "}
                          {isUpgradingSeats && <LoadingSpinner />}
                        </button>
                      )}
                    </div>
                  </>
                )
              )}
            </DialogHeader>
          </>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Change number of seats</DialogTitle>
              <DialogDescription>
                If you add seats, your plan will be prorated into the current
                billing period. If you remove seats, your plan will be updated
                in the next billing period.
              </DialogDescription>
            </DialogHeader>
            <div>
              <div className="font-semibold">
                Current Number of Seats: {numberOfSeats}
              </div>
              <div className="font-semibold">
                Current Number of Members: {numberOfMembers}
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <span className="font-semibold whitespace-nowrap">
                Update number of seats
              </span>
              <Input
                className="w-fit"
                type="number"
                min={1}
                value={tempNumberOfSeats}
                onChange={(e) => setTempNumberOfSeats(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleStartUpdating}
                disabled={
                  tempNumberOfSeats == numberOfSeats ||
                  numberOfMembers > tempNumberOfSeats
                }
                className="btn btn-fuchsia"
              >
                {numberOfSeats < tempNumberOfSeats ? "Upgrade" : "Downgrade"}{" "}
                Seats
              </button>
            </div>
            {tempNumberOfSeats < numberOfMembers && (
              <span className="text-red-500 text-right text-sm">
                You cannot update your number of seats to less than your number
                of members.
              </span>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
