export default function Schedule({ schedule }) {
  console.log(schedule);
  // Assumes there are only two updates.
  const currentSchedule = schedule.find((s) => s.period === "current");
  const futureSchedule = schedule.find((s) => s.period === "future");
  return (
    <div className="grid grid-cols-2">
      <div className="border-1 rounded-lg">
        <div>{currentSchedule.tier}</div>
        <div>{currentSchedule.quantity}</div>
        <div>{currentSchedule.end_date}</div>
      </div>
      <div className="border-1 rounded-lg">
        <div>{futureSchedule.tier}</div>
        <div>{futureSchedule.quantity}</div>
        <div>{futureSchedule.start_date}</div>
      </div>
    </div>
  );
}
