import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { delete_project } from "api";
import { useState } from "react";
import { FaEllipsisVertical } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function DeleteProject({ projectId }) {
  const navigate = useNavigate();

  const [deleteInput, setDeleteInput] = useState("");

  const { error: isDeleteProjectError, trigger: deleteProject } =
    useSWRMutation(
      `delete_project`,
      () => delete_project({ project_id: projectId }),
      {
        onSuccess: () => {
          mutate("count_project", undefined, { revalidate: false });
          mutate((key) => key?.startsWith("list_projects"), undefined, {
            revalidate: false,
          });
          navigate("/");
        },
      }
    );
  return (
    <Dialog>
      <DialogTrigger className="hover:text-fuchsia">
        <FaEllipsisVertical />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Project</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete your project? You will not be able
            to recover your data after deleting this project. If you're sure you
            want to delete this project, please enter "DELETE" in the field
            below.
          </DialogDescription>
        </DialogHeader>
        <Input
        placeholder="Enter DELETE"
          maxLength="6"
          value={deleteInput}
          onChange={(e) => setDeleteInput(e.target.value.toUpperCase())}
        />

        <div className="flex gap-4 w-full justify-end">
          <button
            disabled={deleteInput !== "DELETE"}
            className="btn text-red-500 enabled:hover:underline disabled:text-gray-400"
            onClick={deleteProject}
          >
            Delete Project
          </button>
        </div>
        {isDeleteProjectError && (
          <span className="text-red-500">
            There was an issue deleting this project. Please try again.
          </span>
        )}
      </DialogContent>
    </Dialog>
  );
}
