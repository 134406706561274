import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cancel_plan } from "api/billing";
import { LoadingSpinner } from "components";
import { useIdempotencyKey } from "stores/useIdempotencyKey";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function CancelOrgPlan({ numberOfMembers }) {
  const { idempotencyKey } = useIdempotencyKey();
  const {
    data: hasCanceled,
    trigger: cancelPlan,
    isMutating: isCanceling,
  } = useSWRMutation(
    "cancel_plan",
    () => cancel_plan({ idempotency_key: idempotencyKey }),
    { onSuccess: () => mutate("get_customer_plan") }
  );
  return (
    <Dialog>
      <DialogTrigger className="text-sm border-1 rounded-md p-1 hover:border-fuchsia">
        Cancel Plan
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cancel Plan</DialogTitle>
          <DialogDescription>
            Your plan will be canceled for the next billing period. Are you sure
            you want to cancel your plan?
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end">
          {hasCanceled ? (
            <button className="btn btn-fuchsia">Canceled!</button>
          ) : (
            <button
              className="btn btn-fuchsia"
              onClick={cancelPlan}
              disabled={isCanceling}
            >
              Yes, cancel my plan {isCanceling && <LoadingSpinner />}
            </button>
          )}
        </div>
        {/* {numberOfMembers > 1 && (
          <span className="text-red-500 text-sm text-right">
            You cannot cancel this plan with active members in your
            organization.
          </span>
        )} */}
      </DialogContent>
    </Dialog>
  );
}
