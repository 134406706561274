import InitiumIcon from "assets/initium-logos/brandmark-colored.svg";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { logout } from "api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "stores/useAuthStore";
import useSWRMutation from "swr/mutation";
import {
  BookmarkIcon,
  BotIcon,
  BuildingIcon,
  HouseIcon,
  LogOutIcon,
  MessageCircleHeartIcon,
  SettingsIcon,
} from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { mutate, useSWRConfig } from "swr";

export default function IconSidebar() {
  const { cache } = useSWRConfig();

  const authStore = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { trigger: logUserOut } = useSWRMutation("logout", logout, {
    onSuccess: async () => {
      // Clear any cache on entering login page.
      // Useful for clearing cache when logging out
      await mutate(() => true, undefined, { revalidate: false });
      const keys = [...cache.keys()];
      keys.forEach((key) => cache.delete(key));
      await authStore.logout();
      navigate("/login");
    },
  });

  return (
    <Sidebar
      collapsible="none"
      className="!w-[calc(var(--sidebar-width-icon)_+_1px)] border-r"
    >
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton className="!p-0 justify-center">
              <Link to={"/"} className="p-0 text-center">
                <img className="w-8 h-8" src={InitiumIcon} />
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent className="px-1.5 md:px-0">
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  className="!p-1"
                  isActive={pathname === "/"}
                  asChild
                  tooltip={{ children: "Home", hidden: false }}
                >
                  <Link to={"/"} className="p-0">
                    <HouseIcon />
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  className="!p-1"
                  isActive={pathname === "/organization"}
                  asChild
                  tooltip={{ children: "Organization", hidden: false }}
                >
                  <Link to={"/organization"} className="p-0">
                    <BuildingIcon />
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  className="!p-1"
                  isActive={pathname === "/calls/guidelines-assistant"}
                  tooltip={{
                    children: "Agency Guidelines Assistant",
                    hidden: false,
                  }}
                >
                  <Link to={"/calls/guidelines-assistant"}>
                    <BotIcon className="size-6" />
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  className="!p-1"
                  isActive={pathname.startsWith("/settings")}
                  tooltip={{ children: "Settings", hidden: false }}
                >
                  <Link to={"/settings/profile"}>
                    <SettingsIcon />
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  className="!p-1"
                  tooltip={{ children: "Give Feedback", hidden: false }}
                >
                  <a href="https://forms.gle/rAcrPcuiwendJiQTA" target="_blank">
                    <MessageCircleHeartIcon />
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <Dialog>
          <DialogTrigger asChild>
            <SidebarMenuButton>
              <LogOutIcon />
            </SidebarMenuButton>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle>Logging Out</DialogTitle>
            <DialogDescription>
              Are you sure you want to log out?
            </DialogDescription>
            <div className="flex justify-between">
              <DialogClose>Cancel</DialogClose>
              <button className="btn-fuchsia" onClick={logUserOut}>
                Logout
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </SidebarFooter>
    </Sidebar>
  );
}
