import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cancel_plan } from "api/billing";
import { LoadingSpinner } from "components";
import { useIdempotencyKey } from "stores/useIdempotencyKey";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function CancelProPlan() {
  const { idempotencyKey } = useIdempotencyKey();
  const { trigger: cancelPlan, isMutating: isCanceling } = useSWRMutation(
    "cancel_plan",
    () => cancel_plan({ idempotency_key: idempotencyKey }),
    {onSuccess: () => mutate("get_customer_plan")}
  );

  return (
    <Dialog>
      <DialogTrigger className="text-sm border-1 rounded-md p-1 hover:border-fuchsia">
        Cancel Plan
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cancel Plan</DialogTitle>
          <DialogDescription>
            Cancel your plan for your next billing period.
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end">
          <button
            className="btn btn-fuchsia"
            onClick={cancelPlan}
            disabled={isCanceling}
          >
            Yes, cancel my plan {isCanceling && <LoadingSpinner />}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
