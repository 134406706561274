import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { update_organization_name } from "api/organization";
import Title from "components/Title";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function OrgInfo({
  profile_photo_url,
  num_members,
  org_name,
  total_seats,
}) {
  const [tempOrgName, setTempOrgName] = useState(org_name);

  const { trigger: updateOrgName } = useSWRMutation(
    "update_org_name",
    () => update_organization_name({ new_name: tempOrgName }),
    {
      onSuccess: () => {
        mutate("org_info", (data) => ({ ...data, org_name: tempOrgName }), {
          revalidate: false,
        });
      },
    }
  );

  return (
    <div>
      <Dialog>
        <DialogTrigger className="hover:underline hover:text-fuchsia flex gap-x-2">
          {profile_photo_url && (
            <img width="40" height="40" src={profile_photo_url} />
          )}

          <Title value={org_name} className="text-4xl" />
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update Organization Name</DialogTitle>
            <DialogDescription>
              Please enter a new name for your organization.
            </DialogDescription>
          </DialogHeader>
          <Input
            value={tempOrgName}
            onChange={(e) => setTempOrgName(e.target.value)}
          />
          <div className="flex justify-end">
            <button
              onClick={updateOrgName}
              className="btn btn-fuchsia"
              disabled={org_name === tempOrgName}
            >
              Update
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
