import CancelOrgPlan from "./CancelOrgPlan";
import CancelProPlan from "./CancelProPlan";
import ChangeNumberOfSeats from "./ChangeNumberOfSeats";
import DowngradeToPro from "./DowngradeToPro";
import UpgradeToOrgPlan from "./UpgradeToOrgPlan";

export default function ManagePlan({
  billingConfig,
  customerPlan,
  hasScheduledPlans,
}) {
  const ORG_PLAN = billingConfig?.prices.find(
    (plan) => plan.lookup_key === "org_plan"
  );
  const PRO_PLAN = billingConfig?.prices.find(
    (plan) => plan.lookup_key === "pro_plan"
  );

  if (customerPlan.cancel_at_period_end) return <></>;

  return (
    <div className="flex justify-center p-4">
      <div className="border-1 max-w-3xl w-full rounded-md p-4 flex flex-col gap-y-4">
        <div>
          <div className="font-semibold text-lg text-gray-500 capitalize">
            Manage Plan
          </div>
          {customerPlan.tier === "pro" && (
            <>
              {ORG_PLAN?.id && (
                <UpgradeToOrgPlan
                  org_stripe_id={ORG_PLAN.id}
                  hasScheduledPlans={hasScheduledPlans}
                />
              )}
              <CancelProPlan />
            </>
          )}
          {customerPlan.tier === "organization" && ORG_PLAN?.id && (
            <>
              <ChangeNumberOfSeats
                org_stripe_id={ORG_PLAN.id}
                pro_stripe_id={PRO_PLAN.id}
                numberOfSeats={customerPlan.seats_purchased}
                numberOfMembers={customerPlan.seats_filled}
              />
              <DowngradeToPro
                numberOfMembers={customerPlan.seats_filled}
                pro_stripe_id={PRO_PLAN.id}
              />
              <CancelOrgPlan numberOfMembers={customerPlan.seats_filled} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
