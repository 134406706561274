import { get_settings } from "api/settings";
import useSWR from "swr";

export function useIsAdmin() {
  const {
    data: settings,
    isLoading: isSettingsLoading,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  if (isSettingsLoading) return { isLoading: isSettingsLoading };
  if (settingsError) return { error: settingsError };

  return {
    isAdmin:
      settings.org_relationship === "admin" ||
      settings.org_relationship === "owner",
    isLoading: false,
    error: undefined,
  };
}
