import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  remove_member_from_organization,
  update_member_role,
} from "api/organization";
import LoadingSpinner from "components/LoadingSpinner";
import { EllipsisVerticalIcon } from "lucide-react";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
export default function DeactivatedMemberOptions({ user_name, role, user_id }) {
  const [tempRole, setTempRole] = useState(role);

  const [isReactivateUserDialogOpen, setIsReactivateUserDialogOpen] =
    useState(false);
  const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] = useState(false);

  const { trigger: updateRole, isMutating: isUpdatingRole } = useSWRMutation(
    "update_member_role",
    () => update_member_role({ user_id: user_id, role: tempRole }),
    {
      onSuccess: () => {
        mutate(
          "org_member_list",
          (d) =>
            d.map((user) =>
              user.user_id === user_id
                ? { ...user, status: "active", role: tempRole }
                : user
            ),
          { revalidate: false }
        );
      },
    }
  );
  const { trigger: removeUser, isMutating: isRemovingUser } = useSWRMutation(
    "update_member_role",
    () => remove_member_from_organization({ user_id: user_id }),
    {
      onSuccess: () => {
        mutate(
          "org_member_list",
          (d) => d.filter((user) => user.user_id !== user),
          { revalidate: false }
        );
      },
    }
  );

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="hover:text-fuchsia">
          <EllipsisVerticalIcon />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setIsReactivateUserDialogOpen(true)}>
            Reactivate User
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setIsRemoveUserDialogOpen(true)}>
            Remove User
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        open={isReactivateUserDialogOpen}
        onOpenChange={() => setIsReactivateUserDialogOpen(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Reactivate {user_name}</DialogTitle>
            <DialogDescription>
              Are you sure you want to reactivate this user?
            </DialogDescription>
          </DialogHeader>
          <Select value={tempRole} onValueChange={setTempRole}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={"admin"}>Admin</SelectItem>
              <SelectItem value={"member"}>Member</SelectItem>
            </SelectContent>
          </Select>
          <div className="flex justify-end">
            <button
              onClick={updateRole}
              disabled={isUpdatingRole || role === tempRole}
              className="btn btn-fuchsia"
            >
              Reactivate {isUpdatingRole && <LoadingSpinner />}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isRemoveUserDialogOpen}
        onOpenChange={() => setIsRemoveUserDialogOpen(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Remove {user_name}</DialogTitle>
            <DialogDescription>
              Are you sure you want to remove this user?
            </DialogDescription>
          </DialogHeader>

          <div className="flex justify-end">
            <button
              onClick={removeUser}
              disabled={isRemovingUser}
              className="btn btn-fuchsia"
            >
              Remove {isUpdatingRole && <LoadingSpinner />}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
