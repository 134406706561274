import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { BookMarkedIcon, CalendarClockIcon, FoldersIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

export default function HomeSidebar({ isHomeRoute }) {
  const location = useLocation();
  const { pathname } = location;
  if (!isHomeRoute) return null;
  return (
    <Sidebar collapsible="none" className="hidden flex-1 pt-4 md:flex">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={pathname === `/`}
                >
                  <Link to={`/`}>
                    <FoldersIcon /> Projects
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={pathname === `/calendar`}
                >
                  <Link to={`/calendar`}>
                    <CalendarClockIcon /> Calendar
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={pathname === `/bookmarked-calls`}
                >
                  <Link to={`/bookmarked-calls`}>
                    <BookMarkedIcon /> Bookmarks
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
