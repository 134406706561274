import { ErrorPage, LoadingPage, Title } from "components";
import useSWR from "swr";
import { billing_config, get_customer_plan } from "api/billing";
import CurrentPlan from "./current-plan/CurrentPlan";
import ManagePlan from "./manage-plan/ManagePlan";
import SelectPlan from "./select-plan/SelectPlan";
import Schedule from "./schedule/Schedule";

export default function Billing() {
  const {
    data: customerPlan,
    isLoading: isGettingCustomerPlan,
    error: customerPlanError,
  } = useSWR("get_customer_plan", get_customer_plan);

  const {
    data: billingConfig,
    isLoading: isGettingBillingConfig,
    error: billingConfigError,
  } = useSWR("billing_config", billing_config);

  if (isGettingCustomerPlan || isGettingBillingConfig) return <LoadingPage />;
  if (customerPlanError || billingConfigError) return <ErrorPage />;

  // const { prices } = billingConfig;
  // const prices = billingConfig?.prices;
  // TODO: hardcode handling to get monthly plan
  // const monthlyPrice = prices.find((p) => p.recurring.interval === "month");

  return (
    <div>
      <Title className="text-4xl" value={"Billing"} />
      <CurrentPlan
        customerPlan={customerPlan}
        prompt_upgrade={customerPlan?.prompt_upgrade}
      />

      {customerPlan?.prompt_upgrade ? (
        <SelectPlan billingConfig={billingConfig} />
      ) : (
        <ManagePlan
          billingConfig={billingConfig}
          customerPlan={customerPlan}
          hasScheduledPlans={customerPlan.hasOwnProperty("schedule")}
        />
      )}
      {customerPlan?.schedule && <Schedule schedule={customerPlan.schedule} />}
    </div>
  );
}
