import axiosInstance from "./axiosInstance";

export const get_user_calendar = async () => {
  const response = await axiosInstance({
    url: "/user/calendar",
    method: "GET",
    params: {
      view: "myself",
    },
  });
  return response.data;
};
