import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { LoadingPage } from "components";
import { format } from "date-fns";

export default function GrantEvent({ eventInfo, grantInfo, isLoading }) {
  const [isGrantDescriptionOpen, setIsGrantDescriptionOpen] = useState(false);
  if (isLoading) return <LoadingPage />;

  return (
    <>
      <DialogHeader>
        <DialogTitle>Grant Due</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <div className="flex justify-end">
        <Link
          to={`/project/${eventInfo?.project_id}/admin-space/selected-call`}
          className="btn btn-fuchsia"
        >
          Go to Project
        </Link>
      </div>
      <ScrollArea className="max-h-96">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">Agency</TableCell>
              <TableCell>{grantInfo?.agency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Grant</TableCell>
              <TableCell>{grantInfo?.title}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-semibold">Description</TableCell>
              <TableCell>
                <Collapsible
                  className="text-sm"
                  open={isGrantDescriptionOpen}
                  onOpenChange={(o) => setIsGrantDescriptionOpen(o)}
                >
                  <CollapsibleTrigger className="flex items-center hover:text-fuchsia">
                    {isGrantDescriptionOpen ? "Hide" : "See"} Grant Information
                    <ChevronDownIcon
                      aria-expanded={isGrantDescriptionOpen}
                      className="aria-expanded:rotate-180"
                    />
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div>{grantInfo.description}</div>
                  </CollapsibleContent>
                </Collapsible>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Posted Date</TableCell>
              <TableCell>
                {grantInfo?.posted_date &&
                  format(new Date(grantInfo?.posted_date), "E PP")}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-semibold">Due</TableCell>
              <TableCell>
                {grantInfo?.close_date &&
                  format(new Date(grantInfo?.close_date), "E PP")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Project</TableCell>
              <TableCell>{eventInfo?.project_name}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-semibold">Award Ceiling</TableCell>
              <TableCell>{grantInfo?.award_ceiling}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Url</TableCell>
              <TableCell>{grantInfo?.url}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">
                Eligible Applicants
              </TableCell>
              <TableCell>{grantInfo?.eligible_applicant}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">
                Funding Instrument Type
              </TableCell>
              <TableCell>{grantInfo?.funding_instrument_type}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ScrollArea>
    </>
  );
}
