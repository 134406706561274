import { TableCell, TableRow } from "@/components/ui/table";
import { useIsAdmin } from "@/hooks/use-is-admin";
import ActiveMemberOptions from "./ActiveMemberOptions";
import DeactivatedMemberOptions from "./DeactivatedMemberOptions";
import PendingMemberOption from "./PendingMemberOptions";

export default function Member({
  can_send_email,
  code,
  email,
  expires,
  invited,
  inviter_name,
  user_id,
  user_name,
  status,
  role,
}) {
  const { isAdmin } = useIsAdmin();

  return (
    <>
      <TableRow>
        <TableCell className="flex flex-col">
          <span>{user_name}</span>
          <span className="text-sm">{email}</span>
        </TableCell>
        <TableCell className="capitalize">{status}</TableCell>
        <TableCell className="capitalize">{role}</TableCell>
        <TableCell className="align-center justify-items-end">
          {isAdmin && role !== "owner" && (
            <>
              {status === "active" && (
                <ActiveMemberOptions
                  user_id={user_id}
                  role={role}
                  user_name={user_name}
                />
              )}
              {status === "deactivated" && (
                <DeactivatedMemberOptions
                  user_id={user_id}
                  role={role}
                  user_name={user_name}
                />
              )}
              {status === "pending" && (
                <PendingMemberOption
                  code={code}
                  can_send_email={can_send_email}
                  email={email}
                />
              )}
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
