import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Member from "./Member";
import PendingInvite from "./PendingInvite";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Invite from "../invite/Invite";

export default function List({
  memberList,
  num_members,
  total_seats,
  org_relationship,
}) {
  const [filterMembers, setFilterMembers] = useState("all"); // all, current, pending

  const sortedMemberList = memberList
    .filter((member) => {
      if (filterMembers === "all") return true;
      else if (filterMembers === "current") return member.status === "active";
      else if (filterMembers === "pending") return member.status === "pending";
    })
    .toSorted((a, b) => a.email < b.emai);
  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center">
          <Select value={filterMembers} onValueChange={setFilterMembers}>
            <SelectTrigger className="font-semibold text-2xl p-2 hover:border-fuchsia w-fit">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Members</SelectItem>
              <SelectItem value="current">Current Members</SelectItem>
              <SelectItem value="pending">Pending Members</SelectItem>
            </SelectContent>
          </Select>
          <div className="px-4">
            {org_relationship === "admin" || org_relationship === "owner" ? (
              <>
                {num_members} of {total_seats} total seats used
              </>
            ) : (
              <>{num_members} active seats</>
            )}
          </div>
        </div>
        <Invite />
      </div>
      <div className="border-1 rounded-md">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="">User</TableHead>
              <TableHead className="">Status</TableHead>
              <TableHead className="">Role</TableHead>
              <TableHead className="text-right"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedMemberList.map((member) => (
              <Member
                key={member.email}
                org_relationship={org_relationship}
                {...member}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
