import axiosInstance from "./axiosInstance";
export const get_settings = async () => {
  const response = await axiosInstance({
    url: "/user/settings",
    method: "GET",
  });
  return response.data;
};
export const connect_google_drive = async () => {
  const response = await axiosInstance({
    url: "/user/oauth/google/authorize",
    method: "POST",
  });

  return response.data;
};

export const disconnect_google_drive = async () => {
  const response = await axiosInstance({
    url: "/user/oauth/google/authorize",
    method: "DELETE",
  });
  return response.data;
};
