import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { update_member_role } from "api/organization";
import LoadingSpinner from "components/LoadingSpinner";
import { EllipsisVerticalIcon } from "lucide-react";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function ActiveMemberOptions({ user_id, user_name, role }) {
  const [tempRole, setTempRole] = useState(role);

  const [isUpdateRoleDialogOpen, setIsUpdateRoleDialogOpen] = useState(false);
  const [isDeactivateUserDialogOpen, setIsDeactivateUserDialogOpen] =
    useState(false);

  const { trigger: updateRole, isMutating: isUpdatingRole } = useSWRMutation(
    "update_member_role",
    () => update_member_role({ user_id: user_id, role: tempRole }),
    {
      onSuccess: () => {
        mutate(
          "org_member_list",
          (d) =>
            d.map((user) =>
              user.user_id === user_id ? { ...user, role: tempRole } : user
            ),
          { revalidate: false }
        );
      },
    }
  );

  const { trigger: deactivateMember, isMutating: isDeactivatingMember } =
    useSWRMutation(
      "deactivate_member",
      () => update_member_role({ user_id: user_id, role: "deactivated" }),
      {
        onSuccess: () => {
          mutate(
            "org_member_list",
            (d) =>
              d.map((user) =>
                user.user_id === user_id
                  ? { ...user, status: "deactivated", role: null }
                  : user
              ),
            { revalidate: false }
          );
        },
      }
    );
  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="hover:text-fuchsia">
          <EllipsisVerticalIcon />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setIsUpdateRoleDialogOpen(true)}>
            Update Role
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-red-500 focus:text-red-500"
            onClick={() => setIsDeactivateUserDialogOpen(true)}
          >
            Deactivate User
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        open={isUpdateRoleDialogOpen}
        onOpenChange={setIsUpdateRoleDialogOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {user_name}'s Role</DialogTitle>
            <DialogDescription>
              Update your member's role by selecting from the dropdown.
            </DialogDescription>
          </DialogHeader>
          <Select value={role} onValueChange={setTempRole}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={"admin"}>Admin</SelectItem>
              <SelectItem value={"member"}>Member</SelectItem>
            </SelectContent>
          </Select>
          <div className="flex justify-end">
            <button
              onClick={updateRole}
              disabled={isUpdatingRole || role === tempRole}
              className="btn btn-fuchsia"
            >
              Save {isUpdatingRole && <LoadingSpinner />}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeactivateUserDialogOpen}
        onOpenChange={() => setIsDeactivateUserDialogOpen(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Deactivate {user_name}</DialogTitle>
            <DialogDescription>
              Are you sure you want to deactivate this user? They will lose
              access to their account.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end">
            <button
              onClick={deactivateMember}
              disabled={isDeactivatingMember}
              className="btn bg-red-500 text-white"
            >
              Deactivate {isDeactivatingMember && <LoadingSpinner />}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
