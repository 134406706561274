import useSWRMutation from "swr/mutation";
import useDrivePicker from "react-google-drive-picker";
import { useState } from "react";
import { FaGoogleDrive } from "react-icons/fa6";
import { add_new_review, add_new_review_via_google } from "api/review_space";
import useSWR, { mutate } from "swr";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ErrorPage, LoadingPage, LoadingSpinner } from "components";
import { get_settings } from "api/settings";
import { get_google_access_token } from "api";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import agent_backgrounds from "@/types/agent_backgrounds";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useSettings } from "@/hooks/use-settings";

export default function UploadFromDrive({ projectId }) {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [reviewer1Background, setReviewer1Background] = useState("");
  const [reviewer3Background, setReviewer3Background] = useState("");
  const [googleFileObject, setGoogleFileObject] = useState();
  const { settings, isGettingSettings, settingsError } = useSettings();
  const {
    data: accessToken,
    isLoading: isGettingAccessToken,
    error: accessTokenError,
  } = useSWR(
    settings?.google_drive_integration ? "google_access_token" : null,
    get_google_access_token
  );

  const [openPicker, authResponse] = useDrivePicker();

  const {
    data: fileData,
    isMutating: isFileUploading,
    error: isFileUploadError,
    trigger: uploadFile,
    reset: resetFile,
  } = useSWRMutation(
    `add_new_review_via_google/${projectId}`,
    (_, { arg }) =>
      add_new_review_via_google({
        project_id: projectId,
        google_file_object: googleFileObject,
      }),
    {
      onSuccess: (d) => {
        mutate(`list_reviews/${projectId}`);
      },
    }
  );

  if (isGettingSettings || isGettingAccessToken) return <LoadingPage />;
  if (settingsError || accessTokenError) return <ErrorPage />;

  const handleOpenPicker = () => {
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_PICKER_API_KEY,
      viewId: "DOCS",
      token: accessToken.token, // pass oauth token in case you already have one
      appId: "549549515103",
      viewMimeTypes:
        "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.google-apps.document",
      customScopes: ["https://www.googleapis.com/auth/drive.file"],
      callbackFunction: (data) => {
        if (data.action === "picked") {
          const google_file_object = {
            id: data.docs[0].id,
            name: data.docs[0].name,
            mimeType: data.docs[0].mimeType,
          };
          setGoogleFileObject(google_file_object);
          setIsUploadDialogOpen(true);
          // uploadFile(google_file_object);
        }
      },
    });
  };

  const handleOnOpenChange = (v) => {
    setIsUploadDialogOpen(v);
    resetFile();
  };

  if (settings?.prompt_upgrade)
    return (
      <Tooltip>
        <TooltipTrigger
          className="btn-fuchsia text-sm"
          disabled={settings?.prompt_upgrade}
        >
          <FaGoogleDrive />
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please upgrade to pro to use this feature.
        </TooltipContent>
      </Tooltip>
    );

  if (!settings?.google_drive_integration)
    return (
      <Tooltip>
        <TooltipTrigger className="btn-fuchsia text-sm" disabled={true}>
          <FaGoogleDrive />
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please connect to Google drive in your integration settings.
        </TooltipContent>
      </Tooltip>
    );

  return (
    <>
      <button
        className="btn btn-fuchsia"
        onClick={handleOpenPicker}
        disabled={accessTokenError}
      >
        <FaGoogleDrive />
      </button>
      <Dialog open={isUploadDialogOpen} onOpenChange={handleOnOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Import File from Google</DialogTitle>
            <DialogDescription>{googleFileObject?.name}</DialogDescription>
          </DialogHeader>
          <Select
            value={reviewer1Background}
            onValueChange={setReviewer1Background}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a Background for Reviewer 1" />
            </SelectTrigger>
            <SelectContent>
              {agent_backgrounds.map(({ value, label }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            value={reviewer3Background}
            onValueChange={setReviewer3Background}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a Background for Reviewer 3" />
            </SelectTrigger>
            <SelectContent>
              {agent_backgrounds.map(({ value, label }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="flex justify-end">
            {fileData !== undefined ? (
              <button className="btn btn-fuchsia">Uploaded!</button>
            ) : (
              <button
                className="btn btn-fuchsia"
                onClick={uploadFile}
                disabled={
                  !reviewer1Background ||
                  !reviewer3Background ||
                  isFileUploading
                }
              >
                Upload {isFileUploading && <LoadingSpinner />}
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
