import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import CreatableSelect from "react-select/creatable";

import { delete_brainstorm_idea, update_brainstorm_idea } from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import {
  add_tag_to_brainstorm_idea,
  create_tag,
  remove_tag_from_brainstorm_idea,
} from "api/brainstorming";
import { Badge } from "@/components/ui/badge";

const createOption = (label) => ({
  label,
  value: label,
});

export default function Idea({
  projectId,
  brainstorm_id,
  ai_generated,
  description,
  item,
  tags,
  all_tags,
  index,
  hasEditorAccess,
  handleAddToGeneratedIdeas,
}) {
  const [tempItem, setTempItem] = useState(item);
  const [tempDescription, setTempDescription] = useState(description);
  const [tagInput, setTagInput] = useState("");

  const {
    isMutating: isDeleting,
    error: isDeleteError,
    trigger: deleteBrainstormIdea,
  } = useSWRMutation(
    `delete_brainstorm_idea`,
    () =>
      delete_brainstorm_idea({
        project_id: projectId,
        brainstorm_id: brainstorm_id,
      }),
    {
      onSuccess: () => {
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (data) => {
            return {
              ...data,
              items: data.items.filter(
                (d) => d.brainstorm_id !== brainstorm_id
              ),
            };
          },
          { revalidate: false }
        );
      },
    }
  );

  const { trigger: updateBrainstormIdea } = useSWRMutation(
    "update_brainstorm_idea",
    () =>
      update_brainstorm_idea({
        project_id: projectId,
        brainstorm_idea: {
          brainstorm_id: brainstorm_id,
          description: tempDescription,
          item: tempItem,
        },
      }),
    {
      onSuccess: () =>
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (data) => ({
            ...data,
            items: data.items.map((idea) =>
              idea.brainstorm_id === brainstorm_id
                ? { ...idea, description: tempDescription, item: tempItem }
                : idea
            ),
          }),
          { revalidate: false }
        ),
    }
  );

  const { trigger: createTag } = useSWRMutation(
    "create_tag",
    async (key, { arg }) => {
      await create_tag({ project_id: projectId, tag: arg });
      return arg;
    },
    {
      onSuccess: (data) =>
        mutate(
          (key) =>
            key === `/project/idea/brainstorm/${projectId}` ||
            key?.startsWith(`get_brainstorm_sessions/${projectId}`),
          (o) => ({ ...o, tags: [...o.tags, data] }),
          { revalidate: false }
        ),
    }
  );

  const { trigger: addTagToIdea } = useSWRMutation(
    "add_tag_to_brainstorm_idea",
    async (key, { arg }) => {
      await add_tag_to_brainstorm_idea({
        project_id: projectId,
        tag: arg,
        idea_id: brainstorm_id,
      });
      return arg;
    },
    {
      onSuccess: (data) =>
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (d) => ({
            ...d,
            items: d.items.map((item) =>
              item.brainstorm_id === brainstorm_id
                ? { ...item, tags: item.tags ? [...item.tags, data] : [data] }
                : item
            ),
          }),
          { revalidate: false }
        ),
    }
  );

  const { trigger: removeTagFromIdea } = useSWRMutation(
    "remove_tag_from_idea",
    async (key, { arg }) => {
      console.log(arg);
      await remove_tag_from_brainstorm_idea({
        project_id: projectId,
        tag: arg,
        idea_id: brainstorm_id,
      });
      return arg;
    },
    {
      onSuccess: (data) =>
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (d) => ({
            ...d,
            items: d.items.map((item) =>
              item.brainstorm_id === brainstorm_id
                ? { ...item, tags: item.tags.filter((tag) => tag !== data) }
                : item
            ),
          }),
          { revalidate: false }
        ),
    }
  );

  const handleAddTagToIdea = async (tagInput) => {
    if (!tags?.find((v) => v === tagInput)) {
      await createTag(tagInput);
      await addTagToIdea(tagInput);
    } else {
      addTagToIdea(tagInput);
    }
  };

  const handleKeyDown = async (e) => {
    // if (isError) setIsError(false);
    if (!tagInput) return;
    if (e.key === "Enter" || e.key === "Tab") {
      if (tags.find((v) => v.value === tagInput)) {
        // setIsError(true);
        return;
      }

      handleAddTagToIdea(tagInput);
      setTagInput("");

      e.preventDefault();
    }
  };

  const handleInputChange = async (newValue, context) => {
    if (context.action === "input-change") {
      setTagInput(newValue);
    } else if (context.action === "input-blur") {
      if (!newValue) return;
      if (tags.find((v) => v.value === tagInput)) {
        return;
      }

      setTagInput("");
    }
  };

  const handleDeleteTag = async (newValue, context) => {
    if (context.action === "remove-value") {
      removeTagFromIdea(context.removedValue.value);
    } else if (context.action === "select-option") {
      handleAddTagToIdea(context.option.value);
    }
  };

  return (
    <Dialog>
      <DialogTrigger
        className="text-left px-1 ring-fuchsia enabled:hover:ring-1 rounded-lg"
        disabled={!hasEditorAccess}
      >
        <div>
          {index}. {item}
        </div>
        <div className="text-sm">{description}</div>
        <div className="flex gap-x-1">
          {tags?.map((tag) => (
            <Badge>{tag}</Badge>
          ))}
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Edit Idea</DialogTitle>
          <DialogDescription>
            Edit your idea or description in the fields below.
          </DialogDescription>
        </DialogHeader>
        <div>
          <span className="font-semibold">Idea</span>
          <Input
            className=""
            value={tempItem}
            onChange={(e) => setTempItem(e.target.value)}
          />
        </div>
        <div>
          <span className="font-semibold">Description</span>
          <Textarea
            className="min-h-40"
            value={tempDescription}
            onChange={(e) => setTempDescription(e.target.value)}
          />
        </div>
        <div>
          <span className="font-semibold">Tags</span>
          <CreatableSelect
            className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
            classNames={{
              container: ({ isFocused }) =>
                `${
                  isFocused
                    ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                    : ""
                }`,
            }}
            styles={{
              control: () => {},
            }}
            components={{
              DropdownIndicator: null,
              ClearIndicator: null,
            }}
            isClearable
            isMulti
            onChange={handleDeleteTag}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Add a tag..."
            inputValue={tagInput}
            value={tags?.map((tag) => createOption(tag))}
            options={all_tags?.map((tag) => createOption(tag))}
          />
        </div>
        <div className="flex justify-between">
          <button
            className="btn hover:underline text-red-500"
            onClick={deleteBrainstormIdea}
            disabled={isDeleting}
          >
            Delete {isDeleting ? <LoadingSpinner /> : <MdDelete />}
          </button>
          <button
            disabled={tempItem === item && tempDescription === description}
            className="btn-fuchsia"
            onClick={updateBrainstormIdea}
          >
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
