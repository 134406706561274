import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import clsx from "clsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useSWRMutation from "swr/mutation";
import { invite_member_to_organization } from "api/organization";
import LoadingSpinner from "components/LoadingSpinner";
import { mutate } from "swr";
import InviteLink from "./InviteLink";
import { useIsAdmin } from "@/hooks/use-is-admin";
import { ScrollArea } from "@/components/ui/scroll-area";

const createOption = (label) => ({
  label,
  value: label,
});

export default function Invite({ shareCodes }) {
  const { isAdmin } = useIsAdmin();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isInviteResponseModalOpen, setIsInviteResponseModalOpen] =
    useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inviteMembers, setInviteMembers] = useState([]);
  const [role, setRole] = useState("member");

  const {
    data: invites,
    trigger: inviteMemberToOrganization,
    isMutating: isInvitingMembers,
  } = useSWRMutation(
    "invite_member",
    () =>
      invite_member_to_organization({
        invites: inviteMembers.map(({ value }) => ({
          email: value,
          role: role,
        })),
      }),
    {
      onSuccess: () => {
        setInviteMembers([]);
        setIsInviteModalOpen(false);
        setIsInviteResponseModalOpen(true);
        mutate("org_member_list");
      },
    }
  );

  const handleKeyDown = async (e) => {
    let trimmedInputValue = inputValue.trim();
    if (!trimmedInputValue) return;
    if (
      e.key === "Enter" ||
      e.key === "Tab" ||
      e.key === "," ||
      e.key === " "
    ) {
      if (inviteMembers.find((v) => v.value === trimmedInputValue)) {
        return;
      }

      setInviteMembers((p) => [...p, createOption(trimmedInputValue)]);
      setInputValue("");
      e.preventDefault();
    }
  };

  const handleInputChange = async (newValue, context) => {
    if (context.action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleDeleteUser = async (newValue) => {
    setInviteMembers(newValue);
  };
  if (!isAdmin) return <></>;

  return (
    <>
      <Dialog open={isInviteModalOpen} onOpenChange={setIsInviteModalOpen}>
        <DialogTrigger className="btn btn-fuchsia">
          Invite a Member
        </DialogTrigger>
        <DialogContent className="max-w-4xl ">
          <DialogTitle>Invite Members</DialogTitle>
          <DialogDescription>
            Invite a member to your organization
          </DialogDescription>
          <div className="flex gap-x-2">
            <CreatableSelect
              classNames={{
                container: () => clsx("w-full"),
                control: ({ isFocused }) =>
                  clsx(
                    "border-1 rounded-md hover:border-fuchsia focus:border-fuchsia overflow-y-scroll ",
                    isFocused ? "border-fuchsia max-h-full" : "max-h-10"
                  ),
              }}
              styles={{ container: () => ({}), control: () => ({}) }}
              components={{
                DropdownIndicator: null,
                ClearIndicator: null,
              }}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={handleDeleteUser}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Add people (using their email)"
              value={inviteMembers}
            />
            <Select
              value={role}
              onValueChange={(v) => setRole(v)}
              className="max-w-fit hover:border-fuchsia"
            >
              <SelectTrigger className="max-w-fit hover:border-fuchsia data-[state=open]:border-fuchsia">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {[
                  { label: "Member", value: "member" },
                  { label: "Admin", value: "Admin" },
                ].map(({ label, value }) => (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex justify-end">
            <button
              onClick={inviteMemberToOrganization}
              disabled={inviteMembers.length === 0 || isInvitingMembers}
              className="btn btn-fuchsia"
            >
              Add {isInvitingMembers && <LoadingSpinner />}
            </button>
          </div>
          <div className="border-t-1 pt-2 flex flex-col gap-y-2">
            <div className="font-semibold">Invite Link</div>
            <div className="flex gap-x-2">
              <InviteLink
                shareCode={shareCodes?.find(
                  (shareCode) => shareCode.role === "member"
                )}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isInviteResponseModalOpen}
        onOpenChange={setIsInviteResponseModalOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Invited!</DialogTitle>
            <DialogDescription>
              Below are the new emails invited
            </DialogDescription>
          </DialogHeader>
          <ScrollArea className="max-h-96">
            {invites?.results?.map(({ email, status }) => (
              <div className="flex justify-between">
                <span>{email}</span>
                <span className="capitalize">{status.replace("_", " ")}</span>
              </div>
            ))}
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
}
