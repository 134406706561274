import axiosInstance from "api/axiosInstance";

export const get_profile = async () => {
  const response = await axiosInstance({
    url: "/user/profile/",
    method: "GET",
  });
  return response.data;
};

export const update_user_name = async ({ user_name }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { user_name: user_name },
  });
  return response.data;
};

export const update_research_name = async ({ research_name }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { research_name: research_name },
  });
  return response.data;
};

export const add_user_interests = async ({ interest }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { interests: [interest] },
  });
  return response.data;
};

export const delete_user_interests = async ({ interest }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "DELETE",
    data: { interests: [interest] },
  });
  return response.data;
};

export const add_eligible_applicant_type = async ({ id }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { id: id },
  });
  return response.data;
};

export const delete_eligible_applicant_type = async ({ id }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "DELETE",
    data: { id: id },
  });
  return response.data;
};

export const update_company_name = async ({ company_name }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { company_name: company_name },
  });
  return response.data;
};

export const update_department = async ({ department }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { department: department },
  });
  return response.data;
};

export const update_job_title = async ({ job_title }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { job_title: job_title },
  });
  return response.data;
};

export const update_google_scholar = async ({ scholar_link }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { scholar_link: scholar_link },
  });
  return response.data;
};
export const update_semantic_scholar = async ({ semantic_scholar_link }) => {
  const response = await axiosInstance({
    url: "/user/profile/update",
    method: "POST",
    data: { semantic_scholar_link: semantic_scholar_link },
  });
  return response.data;
};
