import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";

export const useIdempotencyKey = create(
  (set) => ({
    idempotencyKey: uuidv4(),
    generateIdempotencyKey: () => {
      const newUUID = uuidv4();
      set({ idempotencyKey: newUUID });
      return newUUID;
    },
  }),
  {
    name: "idempotency-key-store",
  }
);
